import { FC, useContext } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// Components
import { Modal, Loader, TextField, ModalSaveSection } from '../../../components';
import { Box, Fade, Grid, FormControl, Checkbox, FormControlLabel } from '@mui/material';
// fetch
import {
  updateServiceDefinitionChecklistItem,
  createServiceDefinitionChecklistItem,
} from '../../../fetch';

import { IServiceDefinitionChecklist } from '../../../models';

interface IAddEditServiceCodeChecklistModal {
  open: boolean;
  onClose: () => void;
  currentServiceDefinitionChecklist?: IServiceDefinitionChecklist | null;
  currentServiceDefId: string | null;
  fetchServiceDefinitionChecklist: () => void;
}

const Schema = Yup.object().shape({
  description: Yup.string().required('Required').max(100),
  sortOrder: Yup.number()
    .required('Required')
    .max(10000, 'Sort Order should not be more than 10000'),
  limit: Yup.number().max(10000, 'Frequency should not be more than 10000'),
  serviceDefId: Yup.string().required('Service Definition is required'),
});

export const AddEditServiceCodeChecklistModal: FC<IAddEditServiceCodeChecklistModal> = ({
  open,
  onClose,
  currentServiceDefinitionChecklist,
  currentServiceDefId,
  fetchServiceDefinitionChecklist,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          description: currentServiceDefinitionChecklist?.description ?? '',
          tbid: currentServiceDefinitionChecklist?.tbid ?? 0,
          serviceDefChecklistItemId:
            currentServiceDefinitionChecklist?.serviceDefChecklistItemId ?? '',
          serviceDefId: currentServiceDefId ?? '',
          sortOrder: currentServiceDefinitionChecklist?.sortOrder ?? 0,
          isRequired: currentServiceDefinitionChecklist?.optional ?? true,
          optional: currentServiceDefinitionChecklist?.optional ?? false,
          limit: currentServiceDefinitionChecklist?.limit ?? 0,
          isDeleted: currentServiceDefinitionChecklist?.isDeleted ?? false,
          officeId: currentServiceDefinitionChecklist?.officeId ?? '',
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          values.serviceDefId = currentServiceDefId ?? '';
          values.officeId = user?.officeId ?? '';
          values.optional = !values.isRequired;
          const data = {
            ...values,
          };
          try {
            currentServiceDefinitionChecklist
              ? await updateServiceDefinitionChecklistItem(data)
              : await createServiceDefinitionChecklistItem(data);
            enqueueSnackbar(
              currentServiceDefinitionChecklist
                ? 'Successfully updated Checklist Item!'
                : 'Successfully created Checklist Item!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchServiceDefinitionChecklist();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving Checklist Item, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({ resetForm, isSubmitting, handleSubmit, dirty, isValid, handleChange, values }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
              title={
                currentServiceDefinitionChecklist ? 'Edit Checklist Item' : 'Add Checklist Item'
              }
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box mt={1.5}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="description" label="Description" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="sortOrder" label="Sort Order" type="number" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="limit"
                          label="Frequency (in weeks)"
                          type="number"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="isRequired"
                                checked={values.isRequired}
                                onChange={handleChange}
                              />
                            }
                            label="Required"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                      }}
                      isSaveDisabled={!dirty || isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
