import { Button, Typography, Card, Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useState } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { UserAvatar } from '../../../../user-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBroom,
  faFlask,
  faWrench,
  faGripVertical,
  faRepeat,
  faListCheck,
} from '@fortawesome/free-solid-svg-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ICalendarChanges, ICalendarView, ICalendarEventDetail } from '../../../../../models';
import { OTSDetailPage, ServiceDetailPage, TasksDetailPage } from '../../../../../pages';
import { EditSiteModal } from '../../../../../pages/sites';
import { IColorSet } from '../../../../../models/colors';
import { getLegacyUrl } from '../../../../../helpers';

export interface UnscheduledWorkCardProps {
  workDetail: ICalendarEventDetail;
  changes?: ICalendarChanges;
  colorSet?: IColorSet;
  view: ICalendarView;
}
export const UnscheduledWorkCard: FC<UnscheduledWorkCardProps> = ({
  workDetail,
  changes,
  colorSet,
  view,
}) => {
  const [isServiceModalOpen, setIsServiceModalOpen] = useState<boolean>(false);
  const [isSiteModalOpen, setIsSiteModalOpen] = useState<boolean>(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState<boolean>(false);
  const [isOtsModalOpen, setIsOtsModalOpen] = useState<boolean>(false);

  const { v2Customers } = useFlags();
  const { enqueueSnackbar } = useSnackbar();

  const { userInformation } = workDetail;
  const { username, avatarUrl } = userInformation;
  const backgroundColor = colorSet?.backgroundColor || userInformation.backgroundColor;
  const foregroundColor = colorSet?.foregroundColor || userInformation.foregroundColor;
  const { event } = workDetail;
  const legacyUrl = getLegacyUrl?.();

  let hasChange = !!changes?.[event.eventId];

  const classes = useStyles({ backgroundColor, foregroundColor, hasChange, view });

  return (
    <>
      <Card className={clsx(classes.employeeTaskCard, 'print--avoid-break')}>
        <div className={classes.employeeTaskDetailsContainer}>
          <Box className={classes.taskInfoContainer}>
            {event.eventType !== 'ScheduledTask' && (
              <Box className={classes.gripContainer}>
                <Button
                  variant="text"
                  color="inherit"
                  className={classes.siteName}
                  onClick={() => {
                    if (v2Customers) {
                      setIsSiteModalOpen(true);
                    } else {
                      enqueueSnackbar(`Redirecting...`, {
                        variant: 'info',
                      });
                      window.location.href = `${legacyUrl}/Office/Site/View/${event?.siteId}`;
                    }
                  }}
                >
                  <strong>{event.siteName}</strong>
                </Button>
                <FontAwesomeIcon
                  icon={faGripVertical}
                  size="lg"
                  className={clsx(classes.gripIcon, 'print--none')}
                />
              </Box>
            )}
            {event.address && (
              <Typography className={classes.employeeAddress}>{event.address.street}</Typography>
            )}
          </Box>
          <div className={classes.userAvatarContainer}>
            <Box display="flex" alignItems="center" gap={1} flex={1}>
              <UserAvatar
                className={clsx(classes.userAvatar, 'print--none')}
                src={avatarUrl || undefined}
                userName={username}
                backgroundColor={backgroundColor}
              />
              <Typography className={classes.employeeUsername}>{username}</Typography>
            </Box>
          </div>
        </div>
        <div className={classes.serviceTypeStrip}>
          <div className={classes.serviceTypeIconContainer}>
            {event?.eventType === 'ScheduledTask' && (
              <div className={classes.iconCircle}>
                <FontAwesomeIcon icon={faListCheck} size="sm" />
              </div>
            )}
            {!!event?.recurringServiceId && (
              <div className={classes.iconCircle}>
                <FontAwesomeIcon icon={faRepeat} size="sm" />
              </div>
            )}
            {!!event?.showBeakerIcon && (
              <div className={classes.iconCircle}>
                <FontAwesomeIcon icon={faFlask} size="sm" />
              </div>
            )}
            {!!event?.showBrushIcon && (
              <div className={classes.iconCircle}>
                <FontAwesomeIcon icon={faBroom} size="sm" />
              </div>
            )}
            {!!event?.showWrenchIcon && (
              <div className={classes.iconCircle}>
                <FontAwesomeIcon icon={faWrench} size="sm" />
              </div>
            )}
          </div>
          <Button
            variant="text"
            color="inherit"
            className={classes.serviceName}
            onClick={() => {
              if (event.eventType === 'ScheduledTask') {
                return setIsTaskModalOpen(true);
              }
              if (event.eventType === 'RepairVisit') {
                return setIsOtsModalOpen(true);
              }
              setIsServiceModalOpen(true);
            }}
          >
            {event?.description}
          </Button>
        </div>
      </Card>
      {event?.eventId && isServiceModalOpen && event?.eventId && (
        <ServiceDetailPage
          isModal
          isModalOpen={isServiceModalOpen}
          handleModalClose={() => setIsServiceModalOpen(false)}
          currentServiceId={event?.eventId!}
        />
      )}
      {event && isTaskModalOpen && event?.eventId && (
        <TasksDetailPage
          isModal
          isModalOpen={isTaskModalOpen}
          handleModalClose={() => setIsTaskModalOpen(false)}
          currentTaskId={event.eventId}
        />
      )}
      {event?.siteId && isSiteModalOpen && event?.accountId && (
        <EditSiteModal
          isModalOpen={isSiteModalOpen}
          handleModalClose={() => setIsSiteModalOpen(false)}
          siteId={event.siteId}
          accountId={event.accountId}
        />
      )}
      {event?.eventId && isOtsModalOpen && (
        <OTSDetailPage
          isModal
          isModalOpen={isOtsModalOpen}
          currentRepairId={event.eventId}
          handleModalClose={() => {
            setIsOtsModalOpen(false);
          }}
        />
      )}
    </>
  );
};

const LAPTOP_L_MEDIA_QUERY = '@media (max-width: 1440px)';
const LAPTOP_MEDIA_QUERY = '@media (max-width: 1250px)';
const MOBILE_MEDIA_QUERY = '@media (max-width: 768px)';

const useStyles = makeStyles<
  Theme,
  { backgroundColor: string; foregroundColor: string; hasChange: boolean; view: ICalendarView }
>((theme: Theme) => ({
  employeeTaskCard: {
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderStyle: 'solid',
    borderWidth: ({ hasChange }) => (hasChange ? '1px 1px 1px 1px' : '0px 0px 0px 8px'),
    borderColor: ({ backgroundColor }) => backgroundColor,
    backgroundColor: ({ hasChange, backgroundColor }) => (hasChange ? backgroundColor : ''),
    color: ({ hasChange, foregroundColor }) => (hasChange ? foregroundColor : ''),
    [LAPTOP_MEDIA_QUERY]: {
      borderWidth: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return '1px 1px 1px 1px';
          default:
            return;
        }
      },
    },
    '@media print': {
      boxShadow: 'none !important',
      marginBottom: theme.spacing(1),
    },
  },
  employeeTaskDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 8,
    padding: 10,
    width: '100%',
    [LAPTOP_MEDIA_QUERY]: {
      padding: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 5;
          default:
            return;
        }
      },
    },
    [MOBILE_MEDIA_QUERY]: {
      padding: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 3;
          default:
            return;
        }
      },
    },
  },

  gripContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [LAPTOP_L_MEDIA_QUERY]: {
      alignItems: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 'flex-start';
          default:
            return;
        }
      },
    },
    [LAPTOP_MEDIA_QUERY]: {
      justifyContent: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 'center';
          default:
            return;
        }
      },
    },
  },

  taskInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    [LAPTOP_MEDIA_QUERY]: {
      alignItems: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 'center';
          default:
            return;
        }
      },
    },
  },

  siteName: {
    color: ({ backgroundColor, foregroundColor, hasChange }) =>
      hasChange ? foregroundColor : backgroundColor,
    textDecoration: 'underline',
    fontSize: 14,
    padding: 0,
    textAlign: 'left',
    backgroundColor: `transparent !important`,
    [LAPTOP_MEDIA_QUERY]: {
      fontSize: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 12;
          default:
            return;
        }
      },
      textAlign: 'center',
    },
    [MOBILE_MEDIA_QUERY]: {
      fontSize: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 10;
          default:
            return;
        }
      },
    },
  },
  gripIcon: {
    [LAPTOP_MEDIA_QUERY]: {
      display: 'none',
    },
  },
  employeeAddress: {
    fontSize: 14,
    [LAPTOP_MEDIA_QUERY]: {
      fontSize: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 12;
          default:
            return;
        }
      },
      textAlign: 'center',
    },
    [MOBILE_MEDIA_QUERY]: {
      fontSize: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 10;
          default:
            return;
        }
      },
    },
  },
  userAvatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 5,
    [LAPTOP_MEDIA_QUERY]: {
      flexDirection: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 'column';
          default:
            return;
        }
      },
    },
  },
  userAvatar: {
    height: '35px',
    width: '35px',
    fontSize: '10px',
    marginRight: 0,
    borderStyle: ({ hasChange }) => (hasChange ? 'solid' : ''),
    borderWidth: '1px',
    borderColor: ({ hasChange, foregroundColor }) => (hasChange ? foregroundColor : ''),
    [LAPTOP_MEDIA_QUERY]: {
      height: '25px',
      width: '25px',
    },
  },
  employeeUsername: {
    fontSize: 16,
    textAlign: 'left',
    [LAPTOP_MEDIA_QUERY]: {
      fontSize: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 10;
          default:
            return;
        }
      },
      textAlign: 'center',
    },
  },
  serviceTypeStrip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    padding: 5,
    backgroundColor: '#F2F2F2',
    color: theme.palette.primary.main,
    width: '100%',
    [LAPTOP_L_MEDIA_QUERY]: {
      flexDirection: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 'column';
          default:
            return;
        }
      },
    },
    '@media print': {
      gap: 0,
    },
  },
  serviceTypeIconContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 5,
    '@media print': {
      color: `${theme.palette.common.black} !important`,
      backgroundColor: `${theme.palette.grey[200]} !important`,
      padding: theme.spacing(1),
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
  },
  iconCircle: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#BEE7F1',
    borderRadius: 45,
    fontSize: 14,
    padding: 8,
    [LAPTOP_MEDIA_QUERY]: {
      fontSize: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 10;
          default:
            return;
        }
      },
    },
    [MOBILE_MEDIA_QUERY]: {
      fontSize: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 8;
          default:
            return;
        }
      },
    },
  },
  serviceName: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    textDecoration: 'underline',
    fontSize: 14,
    padding: 2,
    backgroundColor: `transparent !important`,
    [LAPTOP_MEDIA_QUERY]: {
      fontSize: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 12;
          default:
            return;
        }
      },
    },
    [MOBILE_MEDIA_QUERY]: {
      fontSize: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 8;
          default:
            return;
        }
      },
    },
    '@media print': {
      color: `${theme.palette.common.black} !important`,
    },
  },
}));
