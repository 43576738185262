import { FC, useCallback, useContext } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Button } from '@mui/material';
import { useConfirm } from '../../hooks';
import { faFilterCircleXmark, faFilter, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import {
  CardTitle,
  FilterForm,
  GridDataFetcher,
  useDataGrid,
  useFilters,
  CardFiltersLayout,
  Card,
} from '../../components';
import { IEstimate, IFilterLayout } from '../../models';
import { UserContext } from '../../context';
import { getEstimateFilters, getEstimates, deleteEstimate } from '../../fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EstimatesDataGrid } from './estimates-data-grid';

interface EstimateListPageDetailsProps {}

const filtersLayout: Record<string, IFilterLayout> = {
  AccountId: {
    sortOrder: 0,
    xs: 12,
    sm: 6,
    md: 6,
    lg: 4,
  },
  LeadId: {
    sortOrder: 1,
    xs: 12,
    sm: 6,
    md: 6,
    lg: 4,
  },
  Status: {
    sortOrder: 2,
    xs: 12,
    sm: 6,
    md: 6,
    lg: 4,
  },
  EstimateType: {
    sortOrder: 3,
    xs: 12,
    sm: 6,
    md: 6,
    lg: 4,
  },
  ServiceTypeId: {
    sortOrder: 4,
    xs: 12,
    sm: 12,
    md: 6,
    lg: 4,
  },
  CustomAgreementId: { sortOrder: 5, xs: 12, sm: 12, md: 6, lg: 4 },
  CreatedByUserId: {
    sortOrder: 6,
    xs: 12,
    sm: 12,
    md: 6,
    lg: 4,
  },
  WhenCreated: {
    sortOrder: 7,
    xs: 12,
    sm: 12,
    md: 6,
    lg: 4,
  },
  SuppressZeroDollarEstimates: {
    sortOrder: 8,
    xs: 12,
    sm: 6,
    lg: 4,
  },
};

export const EstimateListPageDetails: FC<EstimateListPageDetailsProps> = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const confirm = useConfirm();

  const {
    isShowingFilters,
    filtersInitialized,
    appliedFilters,
    filters,
    filterValues,
    onSubmit: onSubmitFilters,
    onFilterToggle,
    onChange: onFiltersChange,
    onReset,
    isLoading: isLoadingFilters,
  } = useFilters({
    filterFetcher: useCallback(() => getEstimateFilters(), []),
  });

  const dataFetcher: GridDataFetcher<IEstimate> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      try {
        const res = await getEstimates({
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          page: page + 1,
          perPage,
          officeId: user?.officeId,
          whenCreatedStartDate: appliedFilters?.WhenCreated?.[0] ?? undefined,
          whenCreatedEndDate: appliedFilters?.WhenCreated?.[1] ?? undefined,
          filters: appliedFilters,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading estimates, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersInitialized, appliedFilters]
  );

  const handleDelete = async (estimateId: string | number) => {
    const result = await confirm('Are you sure you want to delete this estimate?');
    if (result) {
      try {
        await deleteEstimate(estimateId);
        await refetch();
        enqueueSnackbar('Estimate Deleted!', {
          variant: 'success',
        });
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? 'Error deleting estimate, please try again.', {
          variant: 'error',
        });
      }
    }
  };

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<IEstimate>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'estimates-grid',
      sortColumn: 'whenCreated',
      sortDirection: 'desc',
    },
    dataFetcher,
  });
  return (
    <Card>
      <CardTitle
        cardTitleWrapperClassName={classes.cardTitle}
        title="Estimates"
        mobileWrap
        action={
          <>
            <Button
              onClick={onFilterToggle}
              className={clsx('print--none')}
              color="secondary"
              size="small"
              disabled={isLoadingFilters}
              startIcon={
                <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
              }
            >
              Filters
            </Button>
            <Button
              onClick={() => history.push(`/estimates/new`)}
              color="secondary"
              size="small"
              disabled={isLoading}
              startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
            >
              Add Estimate
            </Button>
          </>
        }
      >
        <CardFiltersLayout isOpen={isShowingFilters}>
          <FilterForm
            filters={filters}
            values={filterValues}
            filterLayouts={filtersLayout}
            defaultLayout={{ xs: 12 }}
            onSubmit={values => {
              onPageChange(0);
              onSubmitFilters(values);
            }}
            onChange={onFiltersChange}
            isSubmitting={isLoading}
            onReset={() => {
              onReset({
                EstimateType: ['All'],
              });
            }}
          />
        </CardFiltersLayout>
      </CardTitle>
      <Box marginTop="1rem">
        <EstimatesDataGrid
          loading={isLoading}
          rows={rows}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          refetch={refetch}
          redirect="/estimates"
          handleDelete={handleDelete}
          isEditable
        />
      </Box>
    </Card>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  cardTitle: { marginBottom: 0 },
}));
