import { FC, ReactNode } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Droppable } from 'react-beautiful-dnd';
import {
  IDayInfo,
} from '../../../../../models';

interface DroppableWeekDayColumnProps {
  dayInfo: IDayInfo;
  children: ReactNode;
}

export const DroppableWeekdayColumn: FC<DroppableWeekDayColumnProps> = ({ dayInfo, children }) => {
  const classes = useStyles();
  return (
    <Droppable droppableId={dayInfo.calendarDate} type="droppableItem">
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={classes.weekdayColumn}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const MOBILE_MEDIA_QUERY = '@media (max-width: 576px)';

const useStyles = makeStyles((theme: Theme) => ({
  weekdayColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    gap: 20,
    padding: 10,
    [MOBILE_MEDIA_QUERY]: {
      padding: 3,
    },
    '@media print': {
      display: 'block',
    },
  },
}));
