import { FC, useContext, useEffect, useState } from 'react';
import {
  Button,
  Box,
  Stack,
  TextField,
  InputAdornment,
  Typography,
  useMediaQuery,
  MenuItem,
} from '@mui/material';
import { Loader, Pagination, Select } from '../../components';
import { useSnackbar } from 'notistack';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getInventory, getParts } from '../../fetch';
import { IInventory } from '../../models';
import { UserContext } from '../../context';
import { InventoryItem } from './inventory-item';

interface IInventorySearchProps {
  onSelectInventoryItem: (item: IInventory) => void;
  autoResultHeight?: boolean;
  isEstimate?: boolean;
}

export const InventorySearch: FC<IInventorySearchProps> = ({
  onSelectInventoryItem,
  autoResultHeight,
  isEstimate,
}) => {
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [inventorySearchText, setInventorySearchText] = useState<string>('');
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [inventory, setInventory] = useState<IInventory[]>([]);
  const [page, setPage] = useState<number>(0);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(10);

  const isSmHeight = useMediaQuery(`(max-height: 568px)`);

  const resultHeight = () => {
    if (autoResultHeight) {
      return 'auto';
    }
    if (isSmHeight) {
      return 'calc(8rem - 52px)';
    }
    return 'calc(65vh - 52px)';
  };

  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [sortMethod, setSortMethod] = useState<string>('');
  const sortMethods = {
    description_desc: 'Description (Desc)',
    description_asc: 'Description (Asc)',
    price_desc: 'Price (Desc)',
    price_asc: 'Price (Asc)',
    quantity_desc: 'Quantity (Desc)',
    quantity_asc: 'Quantity (Asc)',
    manufacturerNumber_desc: 'Manufacturer (Desc)',
    manufacturerNumber_asc: 'Manufacturer (Asc)',
    itemlookupcode_desc: 'Item Lookup Code (Desc)',
    itemlookupcode_asc: 'Item Lookup Code (Asc)',
  };

  const handleInventorySearch = async (customPage: number, customPerPage: number) => {
    setHasSearched(false);
    try {
      setIsSearching(true);
      const response: any = user?.isIndependent
        ? await getParts({
            search: inventorySearchText,
          })
        : await getInventory({
            search: inventorySearchText,
            perPage: customPerPage,
            page: customPage + 1,
            isForLabor: isEstimate ? false : null,
            sortBy: (sortMethod !== 'none' && sortMethod.split('_')[0]) || '',
            sortDirection: (sortMethod !== 'none' && sortMethod.split('_')[1]) || '',
          });
      setInventory(response.records);
      setRecordCount(response.totalRecordCount);
      setHasSearched(true);
    } catch (err: any) {
      enqueueSnackbar(
        err?.response?.data?.Detail || `Error searching inventory. Please try again.`,
        { variant: 'error' }
      );
    } finally {
      setIsSearching(false);
    }
  };

  const initialSearch = () => {
    setPage(0);
    setRowsPerPage(10);
    handleInventorySearch(0, 10);
  };

  const handleFilterChange = () => {
    handleInventorySearch(0, perPage);
    setPage(0);
  };

  useEffect(() => {
    if (sortMethod && sortMethod !== 'none') {
      handleFilterChange();
    }
    if (sortMethod === 'none') {
      setSortMethod('');
      handleFilterChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortMethod]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        initialSearch();
      }}
      style={{ position: 'relative' }}
    >
      <Stack flexDirection="row" gap={1}>
        <TextField
          size="small"
          name="search"
          fullWidth
          label="Find Inventory Item"
          value={inventorySearchText}
          onChange={event => setInventorySearchText(event.target.value)}
          disabled={isSearching}
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faSearch} title="Search Inventory" />
              </InputAdornment>
            ),
          }}
        />
        <Button
          type="submit"
          color="secondary"
          onClick={() => initialSearch()}
          disabled={isSearching || !Boolean(inventorySearchText)}
        >
          Search
        </Button>
      </Stack>
      {isSearching && <Loader position="centered" type="overlay" />}
      {inventory.length === 0 && hasSearched && <Typography align="center">No Results</Typography>}
      {inventory.length > 0 && (
        <>
          <Box mb={2} pt={1.5}>
            <Select
              fullWidth
              size="small"
              autoComplete="nope"
              label="Sort By"
              name="sortby"
              value={sortMethod}
              onChange={(e: any) => {
                setSortMethod(e.target.value);
              }}
              hasClear={!!sortMethod && sortMethod !== 'none'}
              onClear={() => {
                setSortMethod('none');
              }}
            >
              {Object.entries(sortMethods).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            sx={{
              maxHeight: resultHeight(),
              overflow: 'auto',
            }}
          >
            <Box pl={2} pr={2}>
              {inventory.map((item, index) => {
                return (
                  <InventoryItem
                    key={`${index}`}
                    isSearching={isSearching}
                    item={{
                      description: item.description,
                      lookupCode: item.lookupCode,
                      manufacturerNumber: item.manufacturerNumber,
                      price: item.price,
                      quantity: item.quantity,
                    }}
                    onSelectInventoryItem={() => onSelectInventoryItem(item)}
                  />
                );
              })}
            </Box>
            <Box>
              <Pagination
                page={page}
                count={recordCount}
                rowsPerPage={perPage}
                setPage={newPage => {
                  setPage(newPage);
                  // check here so it doesn't fire again when you change the rows per page
                  if (newPage !== page) {
                    handleInventorySearch(newPage, perPage);
                  }
                }}
                setRowsPerPage={rows => {
                  setRowsPerPage(rows);
                  handleInventorySearch(page, rows);
                }}
              />
            </Box>
          </Box>
        </>
      )}
    </form>
  );
};
