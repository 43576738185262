import { axios } from '../helpers';
import qs from 'qs';
import {
  IDropdownResponse,
  IRepair,
  IResponse,
  ICreateRepairVisit,
  IUpdateRepairVisit,
  IRepairVisit,
  IGetFiltersResponse,
  ISavedCreditCard,
  IInvoiceRepair,
  IRepairVisitLaborRes,
  IUpdateRepairVisitPayload,
} from '../models';
import { userSignal } from '../signals';

export const getRepairs = async (filters?: {
  officeId?: string | null;
  accountId?: string | null;
  status?: string;
  progress?: string | null;
  agreementStatus?: string | null;
  invoiceStatus?: string | null;
  visitStatus?: string | null;
  siteId?: string;
  whenCreatedStartDate?: string;
  whenCreatedEndDate?: string;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
}): Promise<IResponse<IRepair[]>> => {
  try {
    const { data } = await axios.get(`/api/repairs`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getRepairsFilters = async (filterFilters?: {
  officeId?: string | null;
  isActive?: boolean;
}) => {
  const { data } = await axios.get<IGetFiltersResponse>('/api/repairs/filters', {
    params: {
      ...filterFilters,
      officeId: filterFilters?.officeId ?? userSignal.value?.officeId ?? '',
    },
    paramsSerializer: params => qs.stringify(params),
  });
  const { filters } = data;
  return filters;
};

export const getRepairVisits = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  perPage?: number;
  page?: number;
  officeId?: string | null;
  serviceDate?: string;
  includeCancelledRepairs?: boolean;
  userId?: string;
  userGroupId?: string;
  repairId?: string;
  serviceStartDate?: string;
  serviceEndDate?: string;
}): Promise<IResponse<IRepair[]>> => {
  try {
    const { data } = await axios.get(`/api/repairs/visits`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getRepairStatuses = async (officeId?: string | null): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`/api/repairs/statuses`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getRepairVisitStatuses = async (
  officeId?: string | null
): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`/api/repairs/visits/statuses`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getRepairProgressTypes = async (
  officeId?: string | null
): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`/api/repairs/progress-types`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getRepairVisit = async (
  repairId: string,
  officeId?: string | null
): Promise<IRepairVisit> => {
  try {
    const { data } = await axios.get(`/api/repairs/visits/${repairId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createRepairVisit = async (
  repairId: string,
  payload: ICreateRepairVisit,
  officeId?: string | null
) => {
  try {
    const { data } = await axios.post(`/api/repairs/${repairId}/visits`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateRepairVisit = async (
  repairVisitId: string,
  payload: IUpdateRepairVisit,
  officeId?: string | null
) => {
  try {
    const { data } = await axios.put(`/api/repairs/visits/${repairVisitId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteRepairVisit = async (repairVisitId: string) => {
  try {
    const { data } = await axios.delete(`/api/repairs/visits/${repairVisitId}`);

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateRepairVisits = async (
  payload: IUpdateRepairVisitPayload,
  filters?: {
    repairId?: string;
  },
  officeId?: string | null
) => {
  try {
    const { data } = await axios.post(
      `/api/repairs/visits/schedule`,
      {
        ...payload,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      {
        params: filters,
        paramsSerializer: params => qs.stringify(params),
      }
    );

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createRepairInvoice = async (
  repairId: string,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post<string>(
      `/api/repairs/${repairId}/estimates/convert-to-invoice`,
      {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      }
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getRepairSavedCreditCards = async (
  repairId: string,
  token: string,
  officeId?: string | null
): Promise<ISavedCreditCard[]> => {
  try {
    const { data } = await axios.get(`/api/repairs/${repairId}/saved-cards`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getRepairStatus = async (
  repairId: string,
  officeId?: string | null
): Promise<string[]> => {
  try {
    const { data } = await axios.get(`/api/repairs/${repairId}/progress-status`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const payInvoiceDeposit = async (
  repairId: string,
  payload: IInvoiceRepair,
  officeId?: string | null
): Promise<any> => {
  try {
    const { data } = await axios.post(`/api/repairs/${repairId}/invoice/deposit`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const payInvoice = async (
  repairId: string,
  payload: IInvoiceRepair,
  officeId?: string | null
): Promise<any> => {
  try {
    const { data } = await axios.post(`/api/repairs/${repairId}/invoice/pay`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getRepariVisitsLabor = async (
  repairId: string,
  officeId?: string | null
): Promise<IRepairVisitLaborRes> => {
  try {
    const { data } = await axios.get(`/api/repairs/${repairId}/visits/labor`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
