import { ButtonProps } from '@mui/base';
import { Button } from '@mui/material';
import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

interface ISaveButton extends ButtonProps {
  text?: string;
  icon?: any;
  handleSave?: (e: React.MouseEvent) => void;
  sx?: any;
}

export const SaveButton: FC<ISaveButton> = ({ text, icon, handleSave, ...props }) => {
  return (
    <Button
      {...props}
      type={handleSave ? 'button' : 'submit'}
      onClick={handleSave}
      startIcon={<FontAwesomeIcon icon={icon ?? faSave} />}
      color="secondary"
      data-testid="save-button"
    >
      {text ?? 'Save'}
    </Button>
  );
};
