import { Autocomplete, Chip, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import { IAccountSimple } from '../../../models';
import clsx from 'clsx';

interface IAccountFilterProps {
  className?: string;
  accounts: IAccountSimple[];
  selectedAccounts: IAccountSimple[];
  onDelete: (account: IAccountSimple) => unknown;
  onChange: (accounts: IAccountSimple[]) => unknown;
}

export const AccountFilter: FC<IAccountFilterProps> = ({
  className,
  accounts,
  selectedAccounts,
  onDelete,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <Autocomplete
      fullWidth
      value={selectedAccounts}
      multiple
      disableClearable
      className={clsx(classes.cardBody, className)}
      classes={{
        paper: classes.paperBorder,
      }}
      onChange={(_event, newValue: IAccountSimple[]) => {
        if (newValue && newValue.length > 0) {
          onChange?.(newValue);
        } else {
          onChange?.([]);
        }
      }}
      options={accounts.filter(t => !selectedAccounts.map(t => t.accountId).includes(t.accountId))}
      getOptionLabel={(option: IAccountSimple) => {
        return option.accountName;
      }}
      renderOption={(props, option: IAccountSimple) => {
        return (
          <li {...props} key={option.accountId}>
            {option.accountName}
          </li>
        );
      }}
      renderTags={accounts =>
        accounts.map(account => (
          <Chip
            key={account.accountId}
            label={account.accountName}
            variant="outlined"
            onDelete={() => onDelete?.(account)}
            className={classes.chip}
            classes={{ root: classes.chipRoot }}
          />
        ))
      }
      renderInput={params => {
        return (
          <TextField
            {...params}
            size="small"
            fullWidth
            autoComplete="none"
            variant="outlined"
            label="Customers"
            placeholder={selectedAccounts.length ? '' : 'Select a customer'}
          />
        );
      }}
    />
  );
};
const useStyles = makeStyles<Theme>(theme => ({
  header: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  container: {
    padding: 0,
  },
  map: {
    marginTop: theme.spacing(4),
  },
  card: {},
  cardBody: {
    borderRadius: theme.spacing(0.5),
  },
  chip: {
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    borderColor: theme.palette.primary.main,
    borderRight: 'none',
    textOverflow: 'ellipsis',
    maxWidth: '175px',
  },
  chipRoot: {
    '& .MuiChip-deleteIcon': {
      fontSize: '30px',
      marginRight: '-14px',
      color: theme.palette.primary.main,
    },
  },
}));
