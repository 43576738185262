import { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

// Components
import {
  Wysiwyg,
  DatePicker,
  TimePicker,
  AccountAutocomplete,
  SiteAutocomplete,
  ScheduledServiceAutocomplete,
  Select,
  SelectAsync,
} from '../../../components';
import { Box, Typography, Grid, FormControlLabel, Checkbox, MenuItem } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import { getNumberRange, formatShortFriendlyDateWithTime } from '../../../helpers';
// fetch
import { getUsers, getTaskPriorityTypes, getTaskDefinitions } from '../../../fetch';
import {
  ITaskDetail,
  IListUser,
  ILookupModel,
  ITaskDefinition,
  IResponse,
  ISiteSimple,
  IAccountSimple,
  IScheduledService,
} from '../../../models';

interface ITaskDetails {
  accountIdParam?: string | null;
  siteIdParam?: string | null;
  currentTask: ITaskDetail | null;
}

export const TaskDetails: FC<ITaskDetails> = ({ accountIdParam, siteIdParam, currentTask }) => {
  const { values, setFieldValue, touched, errors, handleBlur } = useFormikContext<any>();
  const [selectedSite, setSelectedSite] = useState<ISiteSimple | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<IAccountSimple | null>(null);
  const [selectedScheduledService, setSelectedScheduledService] =
    useState<IScheduledService | null>(null);
  const [sites, setSites] = useState<ISiteSimple[] | null>(null);
  const [accounts, setAccounts] = useState<IAccountSimple[] | null>(null);
  const [services, setServices] = useState<IScheduledService[] | null>(null);

  useEffect(() => {
    // Set selected value for site autocomplete
    if (currentTask && !!currentTask?.siteId && sites) {
      const site = sites?.filter(site => site.siteId === currentTask?.siteId);
      setSelectedSite((site && site[0]) ?? null);
    }
  }, [currentTask, sites]);

  useEffect(() => {
    // Set selected value for account autocomplete
    if (currentTask && !!currentTask?.accountId && !!accounts) {
      const account = accounts?.filter(account => account.accountId === currentTask?.accountId);
      setSelectedAccount((account && account[0]) ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTask, accounts]);

  useEffect(() => {
    // Set selected value for service autocomplete
    if (currentTask && !!currentTask?.scheduledServiceId && services) {
      const service = services?.filter(
        service => service.scheduledServiceId === currentTask?.scheduledServiceId
      );
      setSelectedScheduledService((service && service[0]) ?? null);
    }
  }, [currentTask, services]);

  useEffect(() => {
    // if accountId url param, set the accounts autocomplete
    if (accountIdParam && accounts) {
      const account = accounts?.filter(account => account.accountId === accountIdParam);
      setSelectedAccount((account && account[0]) ?? null);
      setFieldValue('accountId', account?.[0]?.accountId ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountIdParam, accounts]);

  useEffect(() => {
    // if siteId url param, set the sites autocomplete
    if (siteIdParam && sites) {
      const site = sites?.filter(site => site.siteId === siteIdParam);
      setSelectedSite((site && site?.[0]) ?? null);
      setFieldValue('siteId', site?.[0]?.siteId ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteIdParam, sites]);
  return (
    <Box mt={1}>
      <Grid container spacing={2} flexWrap={'wrap'}>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <SelectAsync
                label="Task Type"
                name="taskDefId"
                required
                apiRequest={() =>
                  getTaskDefinitions({ perPage: -1, scheduledTaskId: currentTask?.scheduledTaskId })
                }
                transformResponse={(res: IResponse<ITaskDefinition[]>) =>
                  res.records.map(r => ({
                    label: r.description,
                    value: r.taskDefinitionId,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectAsync
                label="Priority"
                name="priorityType"
                required
                apiRequest={() => getTaskPriorityTypes()}
                transformResponse={(res: ILookupModel[]) =>
                  res.map(r => ({
                    label: r.description,
                    value: r.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <SelectAsync
                label="Assigned To"
                name="userId"
                required
                apiRequest={() =>
                  getUsers({ sortBy: 'UserName', sortDirection: 'Asc', perPage: -1, isDisabled: false })
                }
                transformResponse={(res: IResponse<IListUser[]>) =>
                  res.records.map(r => ({
                    label: r.userName,
                    value: r.userId,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" gap={1}>
                <DatePicker
                  label="Date"
                  onChange={(date: any) => {
                    setFieldValue('date', date);
                  }}
                  value={values.date ? new Date(values.date) : null}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: touched?.date && errors?.date,
                      size: 'small',
                      required: true,
                    },
                  }}
                />
                <FormControlLabel
                  sx={{ width: '100%', margin: 0 }}
                  control={<Checkbox name="specify-time" checked={values.specifyTime} />}
                  label="Specify Time?"
                  onChange={(_, checked) => {
                    setFieldValue('specifyTime', checked);
                    if (!checked) {
                      setFieldValue('time', '');
                    }
                  }}
                />
                {values.specifyTime && (
                  <TimePicker
                    label="Time"
                    name="time"
                    value={values.time ? new Date(values.time) : null}
                    onChange={(date: any) => {
                      setFieldValue('time', date);
                    }}
                    error={
                      (!!touched?.time && !!errors?.time) ||
                      values?.time?.toString() === 'Invalid Date' ||
                      !values.time
                    }
                    textFieldHelperText={
                      (!!touched?.time && !!errors?.time) ||
                      values?.time?.toString() === 'Invalid Date' ||
                      !values.time
                        ? 'Invalid Time'
                        : ''
                    }
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection={{
                  xs: 'column',
                  sm: 'row',
                }}
                alignItems={{
                  xs: 'flex-start',
                  sm: 'center',
                }}
                gap={2}
              >
                <Typography>Duration</Typography>
                <Box display="flex" alignItems="center" gap={1} width="100%">
                  <Select label="Hours" name="estimatedHours">
                    {getNumberRange(0, 23, 1)?.map(hour => {
                      return (
                        <MenuItem key={`${hour}`} value={hour}>
                          {hour}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Select label="Minutes" name="estimatedMinutes">
                    {getNumberRange(0, 55, 5)?.map(minute => {
                      return (
                        <MenuItem key={`${minute}`} value={minute}>
                          {minute}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AccountAutocomplete
                setSelectedAccount={val => {
                  setFieldValue('accountId', val?.accountId);
                  setFieldValue('siteId', '');
                  setFieldValue('scheduledServiceId', '');
                  setSelectedAccount(val);
                }}
                labelText="Customer"
                handleBlur={handleBlur}
                selectedAccount={selectedAccount}
                hasError={!!touched.accountId && !!errors?.accountId}
                helperText={
                  !!touched?.accountId && !!errors?.accountId ? errors?.accountId.toString() : ''
                }
                isRequired={false}
                handleOptions={data => setAccounts(data)}
              />
            </Grid>
            {values.accountId && (
              <Grid item xs={12}>
                <SiteAutocomplete
                  isRequired={false}
                  handleChange={val => {
                    setFieldValue('siteId', val);
                    if (!val) {
                      setFieldValue('scheduledServiceId', '');
                    }
                  }}
                  handleBlur={handleBlur}
                  accountId={selectedAccount?.accountId}
                  selectedSite={selectedSite}
                  siteId={values.siteId}
                  handleOptions={sites => setSites(sites)}
                  // don't auto fill here because otherwise the form gets set to dirty and the user didn't actually change anything
                  autoFill={false}
                />
              </Grid>
            )}
            {values.accountId && values.siteId && (
              <Grid item xs={12}>
                <ScheduledServiceAutocomplete
                  isRequired={false}
                  handleChange={val => {
                    setFieldValue('scheduledServiceId', val);
                  }}
                  handleBlur={handleBlur}
                  accountId={values.accountId}
                  siteId={values.siteId}
                  selectedScheduledService={selectedScheduledService}
                  value={values.scheduledServiceId}
                  startDate={values.date}
                  shouldFetch={!!values.accountId && !!values.siteId}
                  shouldRefetch={values.accountId && values.siteId}
                  handleOptions={data => setServices(data)}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Wysiwyg
            label="Description"
            value={values?.comments}
            onChange={val => setFieldValue('comments', val)}
            placeholder="Add description..."
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" gap={2} alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.isCompleted}
                  id="completed-checkbox"
                  onClick={() => {
                    setFieldValue('isCompleted', !values.isCompleted);
                    setFieldValue('whenCompleted', new Date().toISOString());
                  }}
                />
              }
              label="Completed"
            />
            {values.isCompleted && (
              <Typography>
                Completion Date:{' '}
                <Typography color="secondary" component="span">
                  {formatShortFriendlyDateWithTime(values?.whenCompleted)}
                </Typography>
              </Typography>
            )}
          </Box>
        </Grid>
        {values.isCompleted && (
          <Grid item xs={12}>
            <Wysiwyg
              label="Completion Notes"
              value={values?.completionNotes}
              onChange={val => setFieldValue('completionNotes', val)}
              placeholder="Add completion notes..."
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
