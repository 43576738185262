import { FC, useMemo, useState } from 'react';
import { ServerSideDataGrid, ServerSideDataGridProps, DisplayGroup } from '../../components';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, IconButton, useMediaQuery, Stack } from '@mui/material';
import { IInvoiceLineItem } from '../../models';
import { formatDate, formatMoney } from '../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { RefundModal } from './refund-modal';

interface InvoiceLineItemsDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: IInvoiceLineItem[];
  refetch: () => Promise<void>;
  handleEdit: (lineItem: IInvoiceLineItem) => void;
  handleDelete: (lineItem: IInvoiceLineItem) => Promise<void>;
  handleVoid: (lineItem: IInvoiceLineItem) => Promise<void>;
  redirect?: string;
  isProcessed?: boolean;
  transactionDate: string;
  getInvoiceLineItems: () => void;
}

export const InvoiceLineItemsDataGrid: FC<InvoiceLineItemsDataGridProps> = ({
  rows,
  refetch,
  redirect,
  handleEdit,
  handleDelete,
  handleVoid,
  isProcessed,
  transactionDate,
  getInvoiceLineItems,
  ...gridProps
}) => {
  const classes = useStyles();
  const [refundItem, setRefundItem] = useState<IInvoiceLineItem | null>(null);
  const isMobile = useMediaQuery(`(max-width: 960px)`);

  const lineItemsWithoutTaxAndDeposit = useMemo(() => {
    return rows
      .filter(item => item.tranCodeDescription !== 'Tax')
      .filter(item => !item.isSecurityDeposit);
  }, [rows]);

  const depositLineItems = useMemo(() => {
    return rows.filter(item => item.isSecurityDeposit);
  }, [rows]);

  const grandTotalAmount = useMemo(() => {
    if (rows && rows.length > 0) {
      const lineItemsAmount = rows.filter(item => !item.isSecurityDeposit).map(v => v.amount ?? 0);
      const amountSum = lineItemsAmount.reduce((a, b) => a + b, 0);
      return amountSum;
    }
    return 0;
  }, [rows]);

  const totalDepositAmount = useMemo(() => {
    if (rows && rows.length > 0) {
      const depositAmount = rows.filter(item => item.isSecurityDeposit).map(v => v.amount ?? 0);
      const amountSum = depositAmount.reduce((a, b) => a + b, 0);
      // positive number
      return Math.abs(amountSum);
    }
    return 0;
  }, [rows]);

  const taxAmount = useMemo(() => {
    return rows.filter(item => item.tranCodeDescription === 'Tax')?.[0]?.amount ?? 0;
  }, [rows]);

  const subTotal = useMemo(() => {
    return lineItemsWithoutTaxAndDeposit.map(item => item.amount || 0).reduce((a, b) => a + b, 0);
  }, [lineItemsWithoutTaxAndDeposit]);

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'sortOrder',
        headerName: 'Line',
        maxWidth: 75,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'tranCodeDescription',
        headerName: 'Tran Code',
        minWidth: 125,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'lookupCode',
        headerName: 'Lookup Code',
        minWidth: 150,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'details',
        headerName: 'Details',
        flex: 2,
        dynamicHeight: true,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'serialNumber',
        headerName: 'Serial #',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'rate',
        headerName: 'Price',
        isNumber: true,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params: GridRenderCellParams<IInvoiceLineItem>) => {
          const { row: lineItem } = params;
          if (!lineItem.rate) {
            return <></>;
          }
          return <>{lineItem.rate ? formatMoney(lineItem.rate) : '$0.00'}</>;
        },
      },
      {
        field: 'quantity',
        headerName: 'Qty',
        isNumber: true,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        minWidth: 125,
      },
      {
        field: 'amount',
        headerName: 'Amount',
        isNumber: true,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params: GridRenderCellParams<IInvoiceLineItem>) => {
          const { row: lineItem } = params;
          return <>{lineItem.amount ? formatMoney(lineItem.amount) : '$0.00'}</>;
        },
      },
      !isProcessed && {
        field: 'actions',
        headerName: '',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IInvoiceLineItem>) => {
          const { row: lineItem } = params;
          if (typeof lineItem.quantity === 'string') {
            return <></>;
          }
          return (
            <Box display="flex">
              {!lineItem.isSecurityDeposit && (
                <>
                  <IconButton
                    color="primary"
                    title="Edit Line Item"
                    sx={{ marginRight: theme => theme.spacing(1) }}
                    onClick={() => handleEdit(lineItem)}
                  >
                    <FontAwesomeIcon icon={faEdit} size="sm" />
                  </IconButton>
                  <IconButton
                    color={'error'}
                    title={`Delete Line Item`}
                    onClick={() => {
                      handleDelete(lineItem);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} size="sm" />
                  </IconButton>
                </>
              )}
              {/* Void/Refund button only used the security deposit line item*/}
              {lineItem.isSecurityDeposit && (lineItem.isVoidable || lineItem.isRefundable) && (
                <IconButton
                  color="error"
                  title={`${lineItem.isVoidable ? 'Void' : 'Refund'} Line Item`}
                  onClick={() => {
                    if (lineItem.isVoidable) {
                      return handleVoid(lineItem);
                    }
                    if (lineItem.isRefundable) {
                      setRefundItem(lineItem);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faCircleXmark} size="sm" />
                </IconButton>
              )}
            </Box>
          );
        },
      },
    ].filter(Boolean) as GridColDef[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const additionalColumns = [
    {
      invoiceLineItemId: 'Subtotal',
      quantity: 'Subtotal',
      amount: subTotal,
    },
    {
      invoiceLineItemId: 'Sales Tax',
      quantity: `Sales Tax`,
      amount: taxAmount,
    },
    {
      invoiceLineItemId: 'Grand Total',
      quantity: 'Grand Total',
      amount: grandTotalAmount,
    },
    ...rows
      .filter(item => item.tranCodeDescription !== 'Tax')
      .map((item, index) => {
        if (item.isSecurityDeposit) {
          return {
            ...item,
            rate: null,
            sortOrder: null,
            quantity: null,
            tranCodeDescription: formatDate(item?.lineItemBusinessDate ?? transactionDate),
            details: item.details,
            amount: item.amount,
          };
        }
        return null;
      }),
    {
      invoiceLineItemId: 'Amount Due',
      quantity: 'Amount Due',
      amount: grandTotalAmount - totalDepositAmount,
    },
  ];

  return (
    <>
      <ServerSideDataGrid
        autoHeight
        getRowId={(row: IInvoiceLineItem) => row.invoiceLineItemId!}
        rows={
          rows.length > 0
            ? [...lineItemsWithoutTaxAndDeposit, ...(!isMobile ? additionalColumns : [])].filter(
                Boolean
              )
            : []
        }
        getRowClassName={params => {
          const { invoiceLineItemId, details } = params.row;
          if (
            invoiceLineItemId === 'Subtotal' ||
            invoiceLineItemId === 'Sales Tax' ||
            invoiceLineItemId === 'Grand Total' ||
            invoiceLineItemId === 'Amount Due' ||
            details.includes('Security Deposit') ||
            details.includes('PARTIALLY REFUNDED')
          ) {
            return classes.calcRow;
          }
          return '';
        }}
        getCellClassName={params => {
          const { invoiceLineItemId, details } = params.row;
          if (
            invoiceLineItemId === 'Subtotal' ||
            invoiceLineItemId === 'Sales Tax' ||
            invoiceLineItemId === 'Grand Total' ||
            invoiceLineItemId === 'Amount Due' ||
            details.includes('Security Deposit') ||
            details.includes('PARTIALLY REFUNDED')
          ) {
            return classes.calcRow;
          }
          return '';
        }}
        columns={columns}
        disableRowSelectionOnClick
        columnHeaderHeight={36}
        hideFooter={true}
        hasMobileLayout
        mobileProps={{
          mobileDefaultAccessor: 'details',
          handleEdit: (val: IInvoiceLineItem) => {
            handleEdit(val);
          },
          handleDelete: (val: IInvoiceLineItem) => {
            handleDelete(val);
          },
        }}
        {...gridProps}
      />
      {isMobile && (
        <Stack mt={1} mb={1}>
          <DisplayGroup isInline label="Subtotal:" labelId="subTotal">
            {formatMoney(subTotal)}
          </DisplayGroup>
          <DisplayGroup isInline label="Sales Tax:" labelId="sales">
            {formatMoney(taxAmount)}
          </DisplayGroup>
          <DisplayGroup isInline label="Grand Total:" labelId="total">
            {formatMoney(grandTotalAmount)}
          </DisplayGroup>
          {depositLineItems.map((lineItem, index) => {
            return (
              <Box display="flex" alignItems="center" key={lineItem.invoiceLineItemId}>
                <DisplayGroup isInline label="Deposit:" labelId={`depsit-${index}`}>
                  {formatMoney(lineItem.amount)}
                </DisplayGroup>
                {/* Void/Refund button only used the security deposit line item*/}
                {(lineItem.isVoidable || lineItem.isRefundable) && (
                  <IconButton
                    color="error"
                    size="small"
                    title={`${lineItem.isVoidable ? 'Void' : 'Refund'} Line Item`}
                    onClick={() => {
                      if (lineItem.isVoidable) {
                        return handleVoid(lineItem);
                      }
                      if (lineItem.isRefundable) {
                        setRefundItem(lineItem);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faCircleXmark} size="sm" />
                  </IconButton>
                )}
              </Box>
            );
          })}
          <DisplayGroup isInline label="Amount Due:" labelId="subTotal">
            {formatMoney(grandTotalAmount - totalDepositAmount)}
          </DisplayGroup>
        </Stack>
      )}
      <RefundModal
        lineItemId={refundItem?.invoiceLineItemId ?? ''}
        totalAmount={refundItem?.amount ?? 0}
        onClose={(shouldUpdate?: boolean) => {
          if (shouldUpdate) {
            getInvoiceLineItems();
          }
          setRefundItem(null);
        }}
        isOpen={!!refundItem}
      />
    </>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  calcRow: {
    backgroundColor: `${theme.palette.common.white} !important`,
    borderBottom: `0px !important`,
    minHeight: '28px !important',
    maxHeight: '28px !important',
  },
}));
