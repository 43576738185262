import {
  gridPageSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import { FC } from 'react';
import { TablePagination } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

interface DataGridPaginationProps {}

export const DataGridPagination: FC<DataGridPaginationProps> = () => {
  const classes = useStyles();
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const count = useGridSelector(apiRef, gridRowCountSelector);

  const pageCount = Math.ceil(count / pageSize);

  return (
    <div className={classes.dataGridPagination}>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        count={count}
        page={page}
        onPageChange={(_e, newPage) => apiRef.current.setPage(newPage)}
        rowsPerPage={pageSize}
        onRowsPerPageChange={e => apiRef.current.setPageSize(+e.target.value)}
        labelRowsPerPage="Showing:"
      />
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(_e, value) => apiRef.current.setPage(value - 1)}
      />
    </div>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  dataGridPagination: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiTablePagination-actions': {
      display: 'none',
    },
    '& .MuiTablePagination-displayedRows': {
      margin: '0 .25rem 0 .5rem',
    },
    '& .MuiTablePagination-selectLabel + .MuiInputBase-root': {
      margin: '0 4px 0 0',
    },
    '& .Mui-selected': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      fontWeight: 600,
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    '& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)': {
      backgroundColor: 'transparent',
      color: theme.palette.grey[600],
      fontWeight: 600,
    },
  },
}));
