import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import clsx from 'clsx';
import { Link } from '../../../../link';
import { UserAvatar } from '../../../../user-avatar';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faWrench } from '@fortawesome/free-solid-svg-icons';
import { ICalendarEventDetail } from '../../../../../models';
import { IColorSet } from '../../../../../models/colors';

interface ScheduledWorkCardProps {
  workDetail: ICalendarEventDetail;
  colorSet?: IColorSet;
}

export const ScheduledWorkCard: FC<ScheduledWorkCardProps> = ({ workDetail, colorSet }) => {
  const { userInformation, event, hasChanged } = workDetail;
  const { username, avatarUrl } = userInformation;

  const backgroundColor = colorSet?.backgroundColor || userInformation.backgroundColor;
  const foregroundColor = colorSet?.foregroundColor || userInformation.foregroundColor;

  let workRefUrl = '';
  switch (event.eventType) {
    case 'RepairVisit':
      workRefUrl = `/services/repair/${event.eventId}`;
      break;
    case 'ScheduledTask':
      workRefUrl = `/scheduling/tasks/${event.eventId}`;
      break;
  }

  const classes = useStyles({ backgroundColor, foregroundColor, hasChanged });

  const openLink = () => {
    window.open(workRefUrl, '_blank');
  };

  return (
    <Card className={classes.employeeTaskCard}>
      <div className={classes.employeeTaskDetailsContainer}>
        <div className={classes.taskNameContainer}>
          <Link to={workRefUrl} target="_blank" className={classes.taskName}>
            {event.siteName}
          </Link>
          <FontAwesomeIcon
            icon={faGripVertical}
            className={clsx(classes.gripIcon, 'print--none')}
          />
        </div>
        {event.address && (
          <Typography className={classes.employeeAddress}>{event.address.street}</Typography>
        )}
        <div className={classes.userAvatarContainer}>
          <UserAvatar
            className={clsx(classes.userAvatar, 'print--none')}
            src={avatarUrl || undefined}
            userName={username}
            backgroundColor={backgroundColor}
          />
          <Typography className={classes.employeeUsername}>{username}</Typography>
        </div>
      </div>
      <div className={classes.serviceTypeStrip}>
        <FontAwesomeIcon icon={faWrench} size="sm" />
        <div onClick={openLink}>
          <Link to={workRefUrl} target="_blank" className={classes.serviceName}>
            {event.description?.trim() ||
              `See ${event.eventType === 'RepairVisit' ? 'Repair' : 'Task'}`}
          </Link>
        </div>
      </div>
    </Card>
  );
};

const MOBILE_MEDIA_QUERY = '@media (max-width: 576px)';

const useStyles = makeStyles<
  Theme,
  { backgroundColor: string; foregroundColor: string; hasChanged: boolean }
>((theme: Theme) => ({
  employeeTaskCard: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'column',
    borderStyle: 'solid',
    borderWidth: '1px 1px 1px 5px',
    borderColor: ({ backgroundColor }) => backgroundColor,
    backgroundColor: ({ hasChanged, backgroundColor }) => (hasChanged ? backgroundColor : ''),
    color: ({ hasChanged, foregroundColor }) => (hasChanged ? foregroundColor : ''),
    [MOBILE_MEDIA_QUERY]: {
      borderWidth: '1px 1px 1px 1px',
    },
    '@media print': {
      boxShadow: 'none !important',
    },
  },
  employeeTaskDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 10,
    width: '100%',
    textAlign: 'left',
    [MOBILE_MEDIA_QUERY]: {
      padding: 3,
      textAlign: 'center',
      gap: 5,
    },
  },
  taskNameContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  taskName: {
    color: ({ backgroundColor, foregroundColor, hasChanged }) =>
      hasChanged ? foregroundColor : backgroundColor,
    textDecoration: 'underline',
    fontSize: 12,
    [MOBILE_MEDIA_QUERY]: {
      fontSize: 10,
    },
  },
  gripIcon: {
    [MOBILE_MEDIA_QUERY]: {
      display: 'none',
    },
  },
  employeeAddress: {
    [MOBILE_MEDIA_QUERY]: {
      fontSize: 10,
    },
    '@media print': {
      position: 'static',
      color: `${theme.palette.common.black} !important`,
    },
  },
  userAvatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 5,
    [MOBILE_MEDIA_QUERY]: {
      flexDirection: 'column',
    },
  },
  userAvatar: {
    height: '20px',
    width: '20px',
    fontSize: '10px',
    marginRight: 0,
    borderStyle: ({ hasChanged }) => (hasChanged ? 'solid' : ''),
    borderWidth: '1px',
    borderColor: ({ hasChanged, foregroundColor }) => (hasChanged ? foregroundColor : ''),
  },
  employeeUsername: {
    fontSize: 12,
    [MOBILE_MEDIA_QUERY]: {
      textAlign: 'center',
      fontSize: 10,
    },
  },
  serviceTypeStrip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    width: '100%',
    padding: 5,
    whiteSpace: 'pre',
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: ({ foregroundColor }) => foregroundColor,
    [MOBILE_MEDIA_QUERY]: {
      flexDirection: 'column',
      textAlign: 'center',
      gap: 0,
    },
    '@media print': {
      color: `${theme.palette.common.black} !important`,
      backgroundColor: `${theme.palette.grey[200]} !important`,
    },
  },
  serviceName: {
    color: ({ foregroundColor }) => foregroundColor,
    textDecoration: 'underline',
    fontSize: 13,
    [MOBILE_MEDIA_QUERY]: {
      fontSize: 8,
    },
    '@media print': {
      color: `${theme.palette.common.black} !important`,
    },
  },
}));
