import { FC, useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Button, FormControlLabel, Checkbox } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CardTitle,
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
  Card,
} from '../../../components';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { IServiceDefinitionChecklist } from '../../../models';
import {
  deleteServiceDefinitionChecklistItem,
  getServiceDefinitionChecklist,
} from '../../../fetch';
import { faPlusCircle, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useConfirm } from '../../../hooks';
import { AddEditServiceCodeChecklistModal } from './add-edit-service-code-checklist-modal';

interface IServiceCodeParams {
  serviceDefId: string;
}
export const ServiceCodeChecklistTable: FC<IServiceCodeParams> = ({ serviceDefId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingModal, showModal] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [currentServiceDefChecklistItem, setCurrentServiceDefChecklistItem] =
    useState<IServiceDefinitionChecklist | null>(null);
  const confirm = useConfirm();

  const dataFetcher: GridDataFetcher<IServiceDefinitionChecklist> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      try {
        const options: any = {
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          perPage,
          page: page + 1,
          serviceDefId,
        };

        const res = await getServiceDefinitionChecklist(options);
        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading Checklist, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    [enqueueSnackbar, serviceDefId]
  );
  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<IServiceDefinitionChecklist>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'service-code-checklist-grid',
      sortColumn: 'sortOrder',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'sortOrder',
        headerName: 'Sort Order',
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
      },
      {
        field: 'isRequired',
        headerName: 'Required',
        flex: 1,
        renderCell: (params: GridRenderCellParams<IServiceDefinitionChecklist>) => {
          const serviceCodeChecklistRecord = params.row;
          return (
            <Box>
              <FormControlLabel
                control={
                  <Checkbox name="isRequired" checked={serviceCodeChecklistRecord.optional} />
                }
                label=""
                disabled
              />
            </Box>
          );
        },
      },
      {
        field: 'limit',
        headerName: 'Frequency (in weeks)',
        filterable: false,
        flex: 1,
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        flex: 1,
        align: 'center',
        renderCell: (params: GridRenderCellParams<IServiceDefinitionChecklist>) => {
          return (
            <Box display="flex">
              <IconButton
                color="primary"
                title="Edit Checklist Item"
                disabled={isLoading || isDeleting}
                onClick={() => {
                  showModal(true);
                  setCurrentServiceDefChecklistItem(params.row);
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
              <IconButton
                color="error"
                title="Delete Checklist Item"
                disabled={isLoading || isDeleting}
                onClick={async () => {
                  try {
                    const result = await confirm(
                      'Are you sure you want to delete this Checklist Item?'
                    );
                    if (result) {
                      setDeleting(true);
                      await deleteServiceDefinitionChecklistItem(
                        params.row.serviceDefChecklistItemId
                      );
                      await refetch();
                      enqueueSnackbar(`Checklist Item Deleted!`, {
                        variant: 'success',
                      });
                    }
                  } catch (error) {
                    enqueueSnackbar(`Error deleting Checklist Item, please try again.`, {
                      variant: 'error',
                    });
                  } finally {
                    setDeleting(false);
                  }
                }}
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </IconButton>
            </Box>
          );
        },
      },
    ];
  }, [isDeleting, isLoading, confirm, enqueueSnackbar, refetch]);

  return (
    <>
      <Card>
        <CardTitle
          title="Service Code Checklist"
          mobileWrap
          action={
            <>
              <Button
                onClick={() => showModal(true)}
                color="secondary"
                size="small"
                disabled={isLoading || isDeleting}
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              >
                Add Checklist Item
              </Button>
            </>
          }
        />
        <ServerSideDataGrid
          autoHeight
          disableColumnFilter
          disableColumnMenu
          getRowId={(row: IServiceDefinitionChecklist) => row.serviceDefChecklistItemId}
          rows={rows}
          columns={columns}
          loading={isLoading}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
        />
      </Card>
      <AddEditServiceCodeChecklistModal
        open={isShowingModal}
        onClose={() => {
          showModal(false);
          setCurrentServiceDefChecklistItem(null);
        }}
        currentServiceDefinitionChecklist={currentServiceDefChecklistItem}
        currentServiceDefId={serviceDefId}
        fetchServiceDefinitionChecklist={() => refetch()}
      />
    </>
  );
};
