import { CardTitle, DisplayGroup, Loader, Card } from '../../components';
import { refundTransaction } from '../../fetch';
import {
  Divider,
  Box,
  Stack,
  TextField,
  Button,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { formatMoney } from '../../helpers';
import { FC, useContext, useState } from 'react';
import { UserContext } from '../../context';
import { useSnackbar } from 'notistack';
import { useConfirm } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { ITransactionDetail } from '../../models';

interface ITransactionPageDetailsRefundProps {
  transaction?: ITransactionDetail;
  redirect?: string | null;
}

export const TransactionPageDetailsRefund: FC<ITransactionPageDetailsRefundProps> = ({
  transaction,
  redirect,
}) => {
  const [partialRefund, setPartialRefund] = useState('');
  const [isFullRefundProcessing, setIsFullRefundProcessing] = useState(false);
  const [isPartialRefundProcessing, setIsPartialRefundProcessing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const confirm = useConfirm();
  const { user } = useContext(UserContext);
  const [refundFullAmount, setRefundFullAmount] = useState<boolean>(true);
  return (
    <Box mt={2}>
      <Card>
        {(isFullRefundProcessing || isPartialRefundProcessing) && (
          <Loader type="overlay" position="centered" />
        )}
        <CardTitle title="Refunds" />
        <Stack gap={1}>
          <Box>
            <DisplayGroup label="Amount">{formatMoney(transaction?.amount)}</DisplayGroup>
          </Box>
          <Box>
            <FormControlLabel
              color="primary"
              control={
                <Checkbox
                  checked={refundFullAmount}
                  id="refundFullAmountCheckbox"
                  color="primary"
                  onChange={(_, e) => {
                    setRefundFullAmount(e);
                    setPartialRefund('');
                  }}
                />
              }
              label="Refund Full Amount"
            />
          </Box>
          {!refundFullAmount && (
            <Box
              mt={1}
              sx={{
                width: {
                  xs: '100%',
                  sm: '17rem',
                },
              }}
            >
              <TextField
                value={partialRefund}
                size="small"
                fullWidth
                label="Partial Amount to Refund"
                onChange={e => {
                  setPartialRefund(e.target.value);
                }}
                type="number"
                error={
                  (partialRefund.length && Number(partialRefund)) >
                    Math.abs(Number(transaction?.amount)) ||
                  (partialRefund.length > 0 && Number(partialRefund) < 1)
                    ? true
                    : false
                }
                helperText={
                  (partialRefund.length && Number(partialRefund)) >
                  Math.abs(Number(transaction?.amount))
                    ? 'Invalid amount to refund'
                    : partialRefund.length > 0 && Number(partialRefund) < 1
                    ? 'Amount needs to be more than 0.'
                    : false
                }
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </Box>
          )}
        </Stack>
        <Box mt={1} mb={2}>
          <Divider />
        </Box>
        <Box display="flex" gap={1}>
          {refundFullAmount && (
            <Button
              type="button"
              color="primary"
              disabled={isFullRefundProcessing}
              onClick={async () => {
                const result = await confirm(
                  `Are you sure you want to full refund this amount, ${formatMoney(
                    Math.abs(Number(transaction?.amount!))
                  )}?`
                );

                if (result) {
                  try {
                    setIsFullRefundProcessing(true);
                    await refundTransaction(transaction?.transactionId!, {
                      transactionRefundType: 'Full',
                      officeId: user?.officeId!,
                    });
                    enqueueSnackbar('Refund success!', {
                      variant: 'success',
                    });
                    setPartialRefund('');
                    history.push(redirect ?? '/payments');
                  } catch (e: any) {
                    enqueueSnackbar(e?.Detail ?? `Error on full refund, please try again`, {
                      variant: 'error',
                    });
                  } finally {
                    setIsFullRefundProcessing(false);
                  }
                }
              }}
            >
              Process Refund
            </Button>
          )}
          {!refundFullAmount && (
            <Button
              color="primary"
              type="button"
              disabled={
                !partialRefund ||
                Number(partialRefund) > Math.abs(Number(transaction?.amount)) ||
                isPartialRefundProcessing ||
                Number(partialRefund) < 1
              }
              onClick={async () => {
                const result = await confirm(
                  `Are you sure you want to partial refund this amount, ${formatMoney(
                    Number(partialRefund)
                  )}?`
                );

                if (result) {
                  try {
                    setIsPartialRefundProcessing(true);
                    await refundTransaction(transaction?.transactionId!, {
                      transactionRefundType: 'Partial',
                      officeId: user?.officeId!,
                      partialAmountToRefund: Number(partialRefund),
                    });
                    enqueueSnackbar('Refund success!', {
                      variant: 'success',
                    });
                    setPartialRefund('');
                    history.push(redirect ?? '/payments');
                  } catch (e: any) {
                    enqueueSnackbar(e?.Detail ?? `Error on partial refund, please try again`, {
                      variant: 'error',
                    });
                  } finally {
                    setIsPartialRefundProcessing(false);
                  }
                }
              }}
            >
              Process Refund
            </Button>
          )}
        </Box>
      </Card>
    </Box>
  );
};
