import { FC } from 'react';
import { Card } from '../../components';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import makeStyles from '@mui/styles/makeStyles';
import { ISimpleDateRange } from '../../models';

interface IHomeCalendarProps {
  dateRange: ISimpleDateRange;
  handleDateChange: (value: ISimpleDateRange) => void;
}

export const HomeCalendar: FC<IHomeCalendarProps> = ({ dateRange, handleDateChange }) => {
  const classes = useStyles();

  return (
    <Card sxProps={{ height: '100%', paddingBottom: 0 }}>
      <DateCalendar
        value={dateRange.selection.startDate}
        views={['year', 'month', 'day']}
        onChange={newValue => {
          handleDateChange({
            selection: {
              key: 'selection',
              startDate: newValue,
              endDate: newValue,
            },
          });
        }}
        sx={{
          height: '285px',
          width: '100%',
        }}
        classes={{
          root: classes.calendarRoot,
          viewTransitionContainer: classes.calendarContainer,
        }}
      />
    </Card>
  );
};

const useStyles = makeStyles(theme => ({
  calendarRoot: {
    width: '100%',
    '& .Mui-selected, & .Mui-selected:focus, & .Mui-selected:hover': {
      color: `${theme.palette.common.white} !important`,
    },
  },
  calendarContainer: {
    '& > div > div': {
      justifyContent: 'space-between !important',
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1.25),
    },
    '& div[role=row]': {
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1.25),
      justifyContent: 'space-between !important',
    },
  },
}));
