import { FC } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { DaysOfWeek } from '../../models';

interface IDaysAvailableSelectorProps {
  daysAvailable: DaysOfWeek[] | null;
  onChange: (newValue: string[]) => void;
  fullWidth?: boolean;
  id?: string;
}
export const DaysAvailableSelector: FC<IDaysAvailableSelectorProps> = ({
  daysAvailable,
  onChange,
  fullWidth,
  id,
}) => {
  const classes = useStyles({ fullWidth });
  return (
    <ToggleButtonGroup
      value={daysAvailable}
      color="primary"
      sx={{ padding: 0.1, width: fullWidth ? '100%' : 'auto', flexWrap: 'wrap' }}
      aria-label="Days Available"
      onChange={(e: React.MouseEvent<HTMLElement>, newValue: DaysOfWeek[]) => {
        onChange(newValue);
      }}
      id={id}
    >
      <ToggleButton
        value="Monday"
        aria-label="Monday"
        title="Monday"
        className={classes.toggleButton}
      >
        Mon
      </ToggleButton>
      <ToggleButton
        value="Tuesday"
        aria-label="Tuesday"
        title="Tuesday"
        className={classes.toggleButton}
      >
        Tue
      </ToggleButton>
      <ToggleButton
        value="Wednesday"
        aria-label="Wednesday"
        title="Wednesday"
        className={classes.toggleButton}
      >
        Wed
      </ToggleButton>
      <ToggleButton
        value="Thursday"
        aria-label="Thursday"
        title="Thursday"
        className={classes.toggleButton}
      >
        Thu
      </ToggleButton>
      <ToggleButton
        value="Friday"
        aria-label="Friday"
        title="Friday"
        className={classes.toggleButton}
      >
        Fri
      </ToggleButton>
      <ToggleButton
        value="Saturday"
        aria-label="Saturday"
        title="Saturday"
        className={classes.toggleButton}
      >
        Sat
      </ToggleButton>
      <ToggleButton
        value="Sunday"
        aria-label="Sunday"
        title="Sunday"
        className={classes.toggleButton}
      >
        Sun
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const useStyles = makeStyles<Theme, { fullWidth?: boolean }>(theme => ({
  toggleButton: {
    flex: ({ fullWidth }) => (fullWidth ? 1 : undefined),
    [theme.breakpoints.down('sm')]: {
      borderRadius: ({ fullWidth }) => (fullWidth ? 0 : undefined),
      borderLeftColor: ({ fullWidth }) => (fullWidth ? 'rgba(0,0,0,.172)' : undefined),
    },
  },
}));
