import { FormControlLabel, Checkbox, Button, Grid, Typography } from '@mui/material';
import { Card, Loader, TextField } from '../../components';
import { formatMoney } from '../../helpers';
import { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { createEstimateAgreement } from '../../fetch';
import { useSnackbar } from 'notistack';

interface IEstimateAgreementDetails {
  setFieldValue: (key: string, val: any) => void;
  values: any;
  hasEstimateAgreementBeenSigned?: boolean;
  showOtsFields: boolean;
  whenInvoiced: string;
  handleBlur: any;
  showCreateEstimateButton?: boolean;
  agreementStatus?: string;
  fetchEstimate: () => Promise<void>;
  isNewEstimate: boolean;
}

export const EstimateAgreementDetails: FC<IEstimateAgreementDetails> = ({
  setFieldValue,
  values,
  hasEstimateAgreementBeenSigned,
  showOtsFields,
  whenInvoiced,
  handleBlur,
  showCreateEstimateButton,
  agreementStatus,
  fetchEstimate,
  isNewEstimate,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isCreatingAgreement, setIsCreatingAgreement] = useState(false);
  return (
    <Card
      cardTitleProps={{
        title: 'Agreement Details',
        action: showCreateEstimateButton ? (
          <Button
            onClick={async () => {
              try {
                setIsCreatingAgreement(true);
                await createEstimateAgreement(values.estimateId);
                fetchEstimate();
              } catch (error: any) {
                enqueueSnackbar(
                  error?.Detail ?? `Error creating estimate agreement, please try again.`,
                  {
                    variant: 'error',
                  }
                );
              } finally {
                setIsCreatingAgreement(false);
              }
            }}
            color="secondary"
            size="small"
            startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
          >
            Create Agreement
          </Button>
        ) : !isNewEstimate && agreementStatus !== 'NotCreated' ? (
          <Typography color="secondary" fontSize=".9rem" fontWeight="bold">
            Agreement Status: {agreementStatus}
          </Typography>
        ) : undefined,
      }}
    >
      {isCreatingAgreement && <Loader type="overlay" />}
      <Grid container spacing={2}>
        <Grid item xs={12} md={showOtsFields ? 4 : 6}>
          <FormControlLabel
            color="primary"
            control={
              <Checkbox
                checked={values.isItemized}
                id="isItemized"
                color="primary"
                disabled={hasEstimateAgreementBeenSigned}
                onClick={() => {
                  setFieldValue('isItemized', !values.isItemized);
                }}
              />
            }
            label="Show Line Item Prices"
          />
        </Grid>
        {showOtsFields && (
          <>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                color="primary"
                control={
                  <Checkbox
                    checked={values.isCreditCardRequired}
                    id="isCreditCardRequired"
                    color="primary"
                    disabled={hasEstimateAgreementBeenSigned}
                    onClick={() => {
                      setFieldValue('isCreditCardRequired', !values.isCreditCardRequired);
                    }}
                  />
                }
                label="Credit Card Required"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="securityDeposit"
                label="Security Deposit"
                placeholder="0.00"
                value={values.securityDeposit}
                disabled={!!hasEstimateAgreementBeenSigned || !!whenInvoiced}
                onBlur={e => {
                  handleBlur(e);
                  setFieldValue('securityDeposit', formatMoney(e.target.value, 2));
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
};
