import { Typography, Box, SxProps } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Label } from './Label';
import { ISelectOption } from '../../models';
import { Loader } from '../loader';
// Components

export interface IDisplayGroup {
  className?: string;
  labelId?: string;
  labelClasses?: string;
  label: string;
  children: any;
  component?: React.ElementType;
  display?: string;
  wrap?: boolean;
  isDropdownResponse?: boolean;
  dropdownValue?: string;
  apiRequest?: () => Promise<any>;
  transformResponse?: (response: any) => ISelectOption[];
  wrapperStyles?: SxProps;
  isInline?: boolean;
  alignInlineItems?: 'flex-start' | 'center' | 'flex-end';
  inlineLabelWidth?: string;
  wrapperClassName?: string;
  printClasses?: string;
}
export const DisplayGroup: FC<IDisplayGroup> = ({
  className,
  labelId,
  labelClasses,
  label,
  component = 'span',
  display = 'block',
  children,
  wrap = true,
  isDropdownResponse = false,
  dropdownValue,
  apiRequest,
  transformResponse,
  wrapperStyles,
  isInline,
  alignInlineItems,
  inlineLabelWidth,
  printClasses,
  wrapperClassName,
}) => {
  // When isDropdownResponse, the value being returned by the API needs to be converted to the appropriate label value
  // Use apiRequest, dropdownValue (the initial value returned by the API), and transformResponse (if needed) to convert the current value into a label/display value
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayValue, setDisplayValue] = useState<string>(dropdownValue ?? '');

  useEffect(() => {
    if (isDropdownResponse && apiRequest && !!dropdownValue) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const response = await apiRequest();
          let data = transformResponse ? transformResponse(response) : response;
          const filteredData = data.filter((option: any) => option.value === dropdownValue)?.[0]
            ?.label;
          setDisplayValue(filteredData);
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownValue]);
  return (
    <Box
      sx={
        isInline
          ? {
              display: 'flex',
              alignItems: alignInlineItems ?? 'center',
            }
          : { ...wrapperStyles }
      }
      className={clsx('print--avoid-break', printClasses, wrapperClassName)}
    >
      <Label
        id={labelId}
        sx={isInline ? { minWidth: inlineLabelWidth ?? '7rem' } : undefined}
        wrap={wrap}
        className={clsx('print--display-block', labelClasses)}
      >
        {label}
      </Label>
      <Typography
        className={className}
        component={component}
        aria-labelledby={labelId}
        display={display}
        title={isLoading ? 'Loading...' : undefined}
      >
        {isLoading && isDropdownResponse && <Loader position="left" title="" />}
        {isDropdownResponse && !isLoading && displayValue}
        {!isDropdownResponse && !isLoading && children}
      </Typography>
    </Box>
  );
};
