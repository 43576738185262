import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CardTitle,
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
  Card,
} from '../../../components';
import { GridColDef } from '@mui/x-data-grid';
import { IPaymentSetup } from '../../../models';
import {  getAllPaymentSetups } from '../../../fetch';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { AddEditPaymentSetupModal } from './add-edit-payment-setup-modal';
import { UserContext } from '../../../context';

export const PaymentSetupsTable: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingModal, showModal] = useState(false);
  const [currentPaymentSetup, setCurrentPaymentSetup] = useState<IPaymentSetup | null>(null);
  const [isPaymentSetupExists, setPaymentSetupExists] = useState(false);  
  const { user } = useContext(UserContext);

  const dataFetcher: GridDataFetcher<IPaymentSetup> = useCallback(
    async ({ perPage, sortDirection, page }) => {
      try {
        const options: any = {
          sortBy: 'apiLoginId',
          sortDirection: sortDirection || 'asc',
          perPage,
          page: page + 1,
          officeId: user?.officeId as string,
        };

        const res = await getAllPaymentSetups(options);

        setPaymentSetupExists(res.totalRecordCount > 0);

        if (res.totalRecordCount > 1) {
          enqueueSnackbar('This office has multiple payment setups defined.', {
            variant: 'error',
          });
        }

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading payment setup info, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    [enqueueSnackbar, user?.officeId]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<IPaymentSetup>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'payment-setups-grid',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'apiLoginId',
        headerName: 'API Login ID',
        sortable: false,
        flex: 1,
      },
      {
        field: 'transactionKey',
        headerName: 'Transaction Key',
        sortable: false,
        flex: 1,
      },  
    ];
  }, []);

  return (
    <>
      <Card>
        <CardTitle
          title="Payment Setup"
          mobileWrap
          action={
            <>
              <Button
                onClick={() => showModal(true)}
                color="secondary"
                size="small"
                disabled={isLoading || isPaymentSetupExists}
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              >
                Add Payment Setup
              </Button>
            </>
          }
        />
        <ServerSideDataGrid
          autoHeight
          disableColumnFilter
          disableColumnMenu
          getRowId={(row: IPaymentSetup) => row.systemSettingId}
          rows={rows}
          columns={columns}
          loading={isLoading}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
        />
      </Card>
      <AddEditPaymentSetupModal
        open={isShowingModal}
        onClose={() => {
          showModal(false);
          setCurrentPaymentSetup(null);
        }}
        currentPaymentSetup={currentPaymentSetup}
        fetchPaymentSetups={() => refetch()}
      />
    </>
  );
};
