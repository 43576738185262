import { FC, useState, useMemo, useEffect } from 'react';
import { Grid, Stack, Button, useMediaQuery } from '@mui/material';
import {
  CalendarShell,
  EmployeeStatsView,
  DateRangeNavigator,
  DetailedWorkView,
  SaveAndCancelStrip,
  useCalendar,
  CalendarViewDetailsModal,
} from '../../../components';
import {
  IAccountSimple,
  ICalendarDateRange,
  ICalendarMode,
  ICalendarView,
  IEmployeeStatsDetail,
  IListUser,
} from '../../../models';
import startOfWeek from 'date-fns/startOfWeek';
import { endOfWeek } from 'date-fns';
import { faCaretLeft, faRefresh } from '@fortawesome/free-solid-svg-icons';
import {
  DEFAULT_DAY_SETTINGS,
  DEFAULT_MONTH_SETTINGS,
  DEFAULT_WEEK_SETTINGS,
  DEFAULT_WORK_WEEK_SETTINGS,
} from '../../../components/calendar/config';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getAccountsSimple, getUsers } from '../../../fetch';
import { UserFilter } from '../../../components/user-filter';
import { IColorSetMap } from '../../../models/colors';
import {
  getUserColorMap,
  parseCalendarDate,
  toCalendarDate,
  setStartOfWeek,
  setEndOfWeek,
} from '../../../helpers';
import { AccountFilter } from './AccountFilter';
import { useSearchParams } from '../../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CalendarDetails: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const paramStartDate = searchParams.get('startDate');
  const paramEndDate = searchParams.get('endDate');
  const paramMode = searchParams.get('mode');
  const paramView = searchParams.get('view') as ICalendarView | undefined;
  const paramUserFilter = searchParams.get('users');
  const paramAccountsFilter = searchParams.get('accounts');
  const paramDetailDate = searchParams.get('detailDate');
  const paramDetailUserId = searchParams.get('detailUserId');

  const classes = useStyles();
  const [selectedMode, setSelectedMode] = useState(paramMode || ICalendarMode.Summary);
  const [users, setUsers] = useState<IListUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IListUser[]>([]);
  const [userColorMap, setUserColorMap] = useState<IColorSetMap>({});
  const [accounts, setAccounts] = useState<IAccountSimple[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<IAccountSimple[]>([]);
  const [isShowingDetails, setIsShowingDetails] = useState<boolean>(false);
  const [selectedDetail, setSelectedDetail] = useState<IEmployeeStatsDetail | undefined>();
  const [detailStateRestored, setDetailStateRestored] = useState(false);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const [isParamSyncEnabled, setIsParamSyncEnabled] = useState(false);
  const [isSingleViewMode, setIsSingleViewMode] = useState(false);

  const isSmMobile = useMediaQuery(`(max-width: 800px)`);

  const selectedUserIds = useMemo(() => selectedUsers.map(u => u.userId), [selectedUsers]);
  const selectedAccountIds = useMemo(
    () => selectedAccounts.map(a => a.accountId),
    [selectedAccounts]
  );

  let initialDateRange: ICalendarDateRange = {
    startDate: setStartOfWeek(new Date()),
    endDate: setEndOfWeek(new Date()),
  };

  if (paramStartDate && paramEndDate) {
    initialDateRange = {
      startDate: parseCalendarDate(paramStartDate),
      endDate: parseCalendarDate(paramEndDate),
    };
  }

  const goToEmployeeDetails = (detail: IEmployeeStatsDetail) => {
    setSelectedMode(ICalendarMode.DetailedWork);
    setSelectedUsers(users.filter(u => u.userId === detail.userInformation.userId));
  };

  const openDetailsPopover = (detail: IEmployeeStatsDetail) => {
    setSelectedDetail(detail);
    setIsShowingDetails(true);
  };

  const {
    isLoading,
    dateRange,
    setDateRange,
    onDateRangeChange,
    refresh,
    view,
    onViewChange,
    timeStep,
    onTimeStepChange,
    isSaving,
    saveChanges,
    reset,
    updatedCalendarItems,
    scheduledWorkList,
    unscheduledWorkList,
    onUnscheduledWorkChange,
    onScheduledWorkChange,
    changes,
    hasChanges,
    changeCount,
  } = useCalendar({
    dateRange: initialDateRange,
    view: paramView,
    userIds: selectedUserIds,
    accountIds: selectedAccountIds,
    preventLoad: !filtersLoaded,
    onInitialLoad(calendarItems) {
      setIsParamSyncEnabled(true);
      if (!paramDetailDate || !paramDetailUserId) {
        return;
      }
      if (detailStateRestored) {
        return;
      }
      if (!calendarItems.length) {
        return;
      }
      setDetailStateRestored(true);
      const calendarItem = calendarItems.find(i => i.calendarDate === paramDetailDate);
      if (!calendarItem) {
        return;
      }
      const calendarUser = calendarItem.users.find(
        u => u.userInformation.userId === paramDetailUserId
      );
      if (!calendarUser) {
        return;
      }
      const restoredDetail: IEmployeeStatsDetail = {
        calendarItem: calendarItem,
        calendarItemUser: calendarUser,
        userInformation: calendarUser.userInformation,
      };
      openDetailsPopover(restoredDetail);
    },
  });

  useEffect(() => {
    if (!isParamSyncEnabled) {
      return;
    }
    setSearchParams({
      startDate: toCalendarDate(dateRange.startDate),
      endDate: toCalendarDate(dateRange.endDate),
      view,
      mode: selectedMode,
      users: selectedUserIds.length ? selectedUserIds.join(',') : undefined,
      accounts: selectedAccountIds.length ? selectedAccountIds.join(',') : undefined,
      detailDate: selectedDetail?.calendarItem.calendarDate,
      detailUserId: selectedDetail?.userInformation.userId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateRange,
    view,
    selectedMode,
    selectedUserIds,
    selectedAccountIds,
    selectedDetail,
    isParamSyncEnabled,
  ]);

  const daySettings = useMemo(
    () => ({
      ...DEFAULT_DAY_SETTINGS,
      step: timeStep,
    }),
    [timeStep]
  );

  const weekSettings = useMemo(
    () => ({
      ...DEFAULT_WEEK_SETTINGS,
      step: timeStep,
    }),
    [timeStep]
  );

  const workWeekSettings = useMemo(
    () => ({
      ...DEFAULT_WORK_WEEK_SETTINGS,
      step: timeStep,
    }),
    [timeStep]
  );

  const handleBackToSummary = () => {
    onViewChange(ICalendarView.WorkWeek);
    const weekStart = startOfWeek(dateRange.startDate);
    setDateRange({
      startDate: weekStart,
      endDate: endOfWeek(weekStart),
    });
    setSelectedMode(ICalendarMode.Summary);
    setSelectedUsers([]);
  };

  const renderViewComponent = () => {
    switch (selectedMode) {
      case ICalendarMode.Summary:
        return (
          <EmployeeStatsView
            userColorMap={userColorMap}
            dateRange={dateRange}
            calendarItems={updatedCalendarItems}
            view={view}
            daySettings={daySettings}
            workWeekSettings={workWeekSettings}
            weekSettings={weekSettings}
            monthSettings={DEFAULT_MONTH_SETTINGS}
            onEmployeeClick={goToEmployeeDetails}
            onEmployeeViewClick={openDetailsPopover}
          />
        );
      case ICalendarMode.DetailedWork:
        return (
          <DetailedWorkView
            userColorMap={userColorMap}
            view={view}
            dateRange={dateRange}
            scheduledWorkList={scheduledWorkList}
            unscheduledWorkList={unscheduledWorkList}
            onUnscheduledWorkChange={onUnscheduledWorkChange}
            onScheduledWorkChange={onScheduledWorkChange}
            changes={changes}
            daySettings={daySettings}
            workWeekSettings={workWeekSettings}
            weekSettings={weekSettings}
            monthSettings={DEFAULT_MONTH_SETTINGS}
          />
        );
    }
  };

  const renderFooter = () => {
    if (selectedMode === ICalendarMode.DetailedWork) {
      return (
        <SaveAndCancelStrip
          allowCancel={hasChanges}
          allowSave={hasChanges}
          onSave={saveChanges}
          onCancel={reset}
          changeCount={changeCount}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    const loadFilters = async () => {
      try {
        setIsLoadingFilters(true);
        const res = await getUsers({ perPage: -1, isDisabled: false });
        const accountRes = await getAccountsSimple({ perPage: -1 });
        const loadedAccounts = accountRes.records.filter(a => {
          if (a.accountStatus !== 'A') {
            return false;
          }
          return !!a.accountName?.trim();
        });

        setAccounts(loadedAccounts);
        setUsers(res.records);
        setUserColorMap(getUserColorMap(res.records));

        if (paramAccountsFilter) {
          const accountIds = paramAccountsFilter.split(',');
          const paramAccounts = loadedAccounts.filter(a =>
            accountIds.find(id => id === a.accountId)
          );
          setSelectedAccounts(paramAccounts);
        }

        if (paramUserFilter) {
          const userIds = paramUserFilter.split(',');
          const paramUsers = users.filter(u => userIds.find(id => id === u.userId));
          setSelectedUsers(paramUsers);
        }
        setFiltersLoaded(true);
      } finally {
        setIsLoadingFilters(false);
      }
    };
    loadFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDelete = (user: IListUser) => {
    const newSelectedUsers = selectedUsers.filter(u => u !== user);
    setSelectedUsers(newSelectedUsers);
    if (!newSelectedUsers.length) {
      handleBackToSummary();
    }
  };

  const closeDetailsPopover = () => {
    setIsShowingDetails(false);
    // remove data after close animation
    setTimeout(() => setSelectedDetail(undefined), 300);
  };

  useEffect(() => {
    if (isSmMobile) {
      setIsSingleViewMode(true);
      return onViewChange(ICalendarView.Day);
    }
    if (isSingleViewMode && !isSmMobile && view === ICalendarView.Day) {
      setIsSingleViewMode(false);
      return onViewChange(ICalendarView.WorkWeek);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmMobile, onViewChange, isSingleViewMode]);

  return (
    <>
      <Grid
        container
        mb={2}
        spacing={2}
        alignItems="flex-start"
        display="flex"
        justifyContent="space-between"
        className="print--none"
      >
        <Grid item xs={12} md="auto" className={classes.autoFlex}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md="auto" className={classes.autoFlex}>
              <UserFilter
                className={classes.filterControl}
                userColorMap={userColorMap}
                users={users}
                selectedUsers={selectedUsers}
                onChange={users => setSelectedUsers(users)}
                onDelete={handleDelete}
                isSmall
              />
            </Grid>
            <Grid item xs={12} sm={6} md="auto" className={classes.autoFlex}>
              <AccountFilter
                className={classes.filterControl}
                accounts={accounts}
                selectedAccounts={selectedAccounts}
                onChange={accounts => setSelectedAccounts(accounts)}
                onDelete={account =>
                  setSelectedAccounts(selectedAccounts.filter(a => a !== account))
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md="auto" justifyContent={{ xs: 'flex-start', md: 'flex-end' }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} gap={1}>
            {selectedMode !== ICalendarMode.Summary && (
              <Button
                color="inherit"
                startIcon={<FontAwesomeIcon icon={faCaretLeft} />}
                onClick={handleBackToSummary}
                disabled={isLoading}
              >
                Back to Summary
              </Button>
            )}
            <Button
              color="secondary"
              startIcon={<FontAwesomeIcon icon={faRefresh} />}
              onClick={refresh}
              disabled={isLoading}
            >
              Refresh
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <CalendarShell
        isSaving={isSaving}
        isLoading={isLoading || isLoadingFilters}
        view={view}
        onSelectedTimeStep={onTimeStepChange}
        showStepPicker={false}
        selectedTimeStep={timeStep}
        onViewChange={onViewChange}
        navigationControl={
          <DateRangeNavigator
            view={view}
            dateRange={dateRange}
            onChange={onDateRangeChange}
            color="white"
          />
        }
        footerControl={renderFooter()}
      >
        {renderViewComponent()}
      </CalendarShell>

      <CalendarViewDetailsModal
        open={isShowingDetails}
        onClose={closeDetailsPopover}
        details={selectedDetail}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  modeDropdown: {
    background: '#6e6c6c',
    borderColor: '#6e6c6c',
  },
  filterControl: {
    minWidth: 200,
  },
  filterLabel: {
    fontSize: 16,
  },
  actionsGrid: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  autoFlex: {
    flex: '1 1 auto',
  },
}));
