import { FC, ReactNode } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Droppable } from 'react-beautiful-dnd';
import { toCalendarDate } from '../../../../../helpers';
import { isEqual, startOfDay } from 'date-fns';

interface DroppableMonthDayCellProps {
  date: Date;
  children: ReactNode;
}

export const DroppableMonthDayCell: FC<DroppableMonthDayCellProps> = ({ date, children }) => {
  const isToday = isEqual(startOfDay(date), startOfDay(new Date()));
  const classes = useStyles({ isToday });
  return (
    <Droppable droppableId={toCalendarDate(date)} type="droppableItem">
      {provided => (
        <div ref={provided.innerRef} {...provided.droppableProps} className={classes.monthDayCell}>
          <div className={classes.dateLabel}>{date.getDate()}</div>
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const MOBILE_MEDIA_QUERY = '@media (max-width: 576px)';

const useStyles = makeStyles<Theme, { isToday: boolean }>((theme: Theme) => ({
  monthDayCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    gap: 10,
    padding: 10,
    overflow: 'hidden',
    [MOBILE_MEDIA_QUERY]: {
      padding: 3,
    },
    '@media print': {
      display: 'block',
    },
  },

  dateLabel: {
    color: ({ isToday }) => (isToday ? theme.palette.primary.main : theme.palette.secondary.main),
    fontWeight: ({ isToday }) => (isToday ? 600 : ''),
  },
}));
