import { FC } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
let buildConfig = require('../../buildSettings');

interface ReportPdfPreviewProps {
  reportGuid: string | null;
}

export const ReportPdfPreview: FC<ReportPdfPreviewProps> = ({ reportGuid }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <Worker workerUrl="/pdfjs-dist@2.7.570/es5/build/pdf.worker.js">
      <div style={{ height: '950px' }}>
        <Viewer
          fileUrl={`${buildConfig.REACT_APP_API_URL}/api/reports/generated/${reportGuid}`}
          withCredentials
          plugins={[defaultLayoutPluginInstance]}
        />
      </div>
    </Worker>
  );
};
