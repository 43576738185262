import {
  GridDataFetcher,
  useDataGrid,
  useFilters,
  ServerSideDataGrid,
  CardTitle,
  CardFiltersLayout,
  FilterForm,
  Loader,
  Card,
} from '../../components';
import { Box, IconButton, Typography, Tooltip, Button } from '@mui/material';
import { faFilterCircleXmark, faFilter, faEye } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useContext, useCallback, useState } from 'react';
import { UserContext } from '../../context';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { formatDate, formatMoney } from '../../helpers';
import { getTransactionBatches, getTransactionBatchFilters } from '../../fetch';
import { ITransactionBatchListModel, IFilterLayout } from '../../models';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddNewBatchButton } from './add-new-batch-button';

const filtersLayout: Record<string, IFilterLayout> = {
  UserId: {
    sortOrder: 0,
    xs: 12,
    md: 6,
  },
  WhenCreated: {
    sortOrder: 1,
    xs: 12,
    md: 6,
  },
  Status: {
    sortOrder: 2,
    xs: 12,
    md: 6,
  },
  Reference: {
    sortOrder: 3,
    xs: 12,
    md: 6,
  },
};

export const BatchesListDetails = () => {
  const [isCreating, setCreating] = useState(false);
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const {
    isShowingFilters,
    filtersInitialized,
    appliedFilters,
    filters,
    filterValues,
    onSubmit: onSubmitFilters,
    onFilterToggle,
    onChange: onFiltersChange,
    onReset,
  } = useFilters({
    filterFetcher: useCallback(() => getTransactionBatchFilters(), []),
  });

  const dataFetcher: GridDataFetcher<ITransactionBatchListModel> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      try {
        if (!filtersInitialized) {
          return {
            continueLoading: true,
          };
        }
        const res = await getTransactionBatches({
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          page: page + 1,
          perPage,
          officeId: user?.officeId,
          ...appliedFilters,
          whenCreatedStartDate: appliedFilters?.WhenCreated?.[0] ?? undefined,
          whenCreatedEndDate: appliedFilters?.WhenCreated?.[1] ?? undefined,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading payment batches, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appliedFilters, filtersInitialized]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
  } = useDataGrid<ITransactionBatchListModel>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'batches-grid',
      sortColumn: 'whenCreated',
      sortDirection: 'desc',
    },
    dataFetcher,
  });

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'whenCreated',
        headerName: 'When Created',
        flex: 1,
        maxWidth: 150,
        renderCell: (params: GridRenderCellParams<ITransactionBatchListModel>) => {
          const { row: paymentBatch } = params;
          return <>{paymentBatch?.whenCreated ? formatDate(paymentBatch.whenCreated) : ''}</>;
        },
      },
      {
        field: 'userName',
        headerName: 'Created By',
        flex: 1,
        maxWidth: 150,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        maxWidth: 150,
        renderCell: (params: GridRenderCellParams<ITransactionBatchListModel>) => {
          const { row: paymentBatch } = params;
          const isActive = paymentBatch.status === 'Active';
          return (
            <Typography
              sx={
                isActive
                  ? { color: '#41D090', fontWeight: 'bold', fontSize: '14px' }
                  : { fontWeight: 'bold', fontSize: '14px' }
              }
            >
              {paymentBatch.status}
            </Typography>
          );
        },
      },
      {
        field: 'reference',
        headerName: 'Description',
        flex: 2,
      },
      {
        field: 'quantity',
        headerName: 'Qty',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'totalAmount',
        headerName: 'Total',
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams<ITransactionBatchListModel>) => {
          const { row: paymentBatch } = params;
          return <>{paymentBatch?.totalAmount ? formatMoney(paymentBatch.totalAmount) : '$0.00'}</>;
        },
      },
      {
        field: 'actions',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        headerName: '',
        renderCell: (params: GridRenderCellParams<ITransactionBatchListModel>) => {
          const { row: paymentBatch } = params;
          return (
            paymentBatch && (
              <Tooltip title="View Batch" placement="bottom">
                <IconButton
                  color="secondary"
                  onClick={() => {
                    if (paymentBatch.type === 'Credit') {
                      return history.push(`/payments/online/${paymentBatch.transactionBatchId}`);
                    }
                    return history.push(`/payments/payment/${paymentBatch.transactionBatchId}`);
                  }}
                >
                  <FontAwesomeIcon icon={faEye} />
                </IconButton>
              </Tooltip>
            )
          );
        },
      },
    ];
  }, [history]);

  const activeBatchId: number | null = useMemo(() => {
    const currentDate = formatDate(new Date());
    const results = rows.filter(
      row =>
        row.status === 'Active' &&
        user?.userName === row.userName &&
        formatDate(row.whenCreated) === currentDate
    );
    return results?.length > 0 ? results?.[0]?.transactionBatchId : null;
  }, [rows, user]);

  return (
    <>
      <Box marginTop="1rem">
        <Card>
          {isCreating && <Loader type="overlay" position="centered" />}
          <CardTitle
            title="Payment Batch list"
            mobileWrap
            marginBottom={0}
            action={
              <>
                <Button
                  onClick={onFilterToggle}
                  className="print--none"
                  color="secondary"
                  size="small"
                  disabled={isLoading}
                  startIcon={
                    <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
                  }
                >
                  Filters
                </Button>
                <AddNewBatchButton
                  setCreating={setCreating}
                  activeBatchId={activeBatchId}
                  handleClick={batchId => history.push(`/payments/payment/${batchId}`)}
                  user={user}
                  isDisabled={isLoading}
                />
              </>
            }
          >
            <CardFiltersLayout isOpen={isShowingFilters}>
              <FilterForm
                filters={filters}
                values={filterValues}
                filterLayouts={filtersLayout}
                defaultLayout={{ xs: 12 }}
                onSubmit={values => {
                  onPageChange(0);
                  onSubmitFilters(values);
                }}
                onChange={onFiltersChange}
                isSubmitting={isLoading}
                onReset={onReset}
              />
            </CardFiltersLayout>
          </CardTitle>
          <Box marginTop="1rem">
            <ServerSideDataGrid
              autoHeight
              getRowId={(row: ITransactionBatchListModel) => row.transactionBatchId}
              rows={rows}
              columns={columns}
              loading={isLoading}
              rowCount={recordCount}
              page={page}
              pageSize={perPage}
              sortModel={sortModel}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onSortModelChange={onSortModelChange}
              hasMobileLayout
              mobileProps={{
                useFirstCell: true,
                handleView: (val: ITransactionBatchListModel) => {
                  if (val.type === 'Credit') {
                    return history.push(`/payments/online/${val.transactionBatchId}`);
                  }
                  return history.push(`/payments/payment/${val.transactionBatchId}`);
                },
              }}
            />
          </Box>
        </Card>
      </Box>
    </>
  );
};
