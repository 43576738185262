import {
  faEdit,
  faEye,
  faFilter,
  faFilterCircleXmark,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Box,
  ListItem,
  ListItemButton,
  IconButton,
  Stack,
  Tooltip,
  Button,
  Divider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useMemo, useState, useCallback } from 'react';
import {
  CardTitle,
  Link,
  ExternalLink,
  CardFiltersLayout,
  useDataGrid,
  GridDataFetcher,
  useFilters,
  ServerSideDataGrid,
  FilterForm,
  TableActionsMenu,
  Card,
  Tabs,
  ITab,
} from '../../../components';
import { getRepairs, getRepairsFilters } from '../../../fetch';
import { formatDate, getLegacyUrl, hyphenSeparateTwoInputs } from '../../../helpers';
import { IFilterLayout, IRepair } from '../../../models';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { theme } from '../../../styles';
import { OTSSchedulerModal } from './scheduler';

export interface IOTSLandingServices {
  redirect?: string;
  title?: string;
  accountId?: string;
  siteId?: string;
  isCollapsible?: boolean;
  initialExpand?: boolean;
  isExpanded?: boolean;
  isEditable?: boolean;
  onNavigation?: (route: string) => unknown;
}

export const OTSLandingServices: FC<IOTSLandingServices> = ({
  redirect,
  title,
  accountId,
  siteId,
  isCollapsible = false,
  initialExpand = false,
  isExpanded,
  isEditable = true,
  onNavigation,
}) => {
  const { v2Customers } = useFlags();
  const history = useHistory();
  const legacyUrl = getLegacyUrl?.();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(`(max-width: 700px)`);
  const OpenStatus = 'Open';
  const ClosedStatus = 'Closed';
  const CancelledStatus = 'Cancelled';
  const [selectedTab, setSelectedTab] = useState<string>('O');
  const [currentTabStatus, setCurrentTabStatus] = useState<string>('');
  const [statusReset, setStatusReset] = useState(false);
  const [isOtsSchedulerModalOpen, setOtsSchedulerModal] = useState<boolean>(false);

  const filtersLayout: Record<string, IFilterLayout> = {
    Statuses: {
      sortOrder: 0,
      hide: selectedTab === 'C' || selectedTab === 'X',
      xs: 12,
      md: 6,
      lg: 4,
    },
    SiteId: {
      sortOrder: 1,
      xs: 12,
      md: 6,
      lg: selectedTab === 'C' || selectedTab === 'X' ? 6 : 4,
      hide: !!accountId,
    },
    Progress: {
      sortOrder: 2,
      hide: selectedTab === 'C' || selectedTab === 'X',
      xs: 12,
      md: 6,
      lg: 4,
    },
    WhenCreated: {
      sortOrder: 3,
      xs: 12,
    },
    InvoiceStatus: {
      sortOrder: selectedTab === 'C' || selectedTab === 'X' ? 2 : 3,
      xs: 12,
      md: 6,
      lg: selectedTab === 'C' || selectedTab === 'X' ? 6 : 4,
    },
    AgreementStatus: {
      sortOrder: 4,
      xs: 12,
      md: 6,
      lg: 4,
    },
    VisitStatus: {
      sortOrder: 5,
      xs: 12,
      md: 6,
      lg: 4,
    },
  };

  const {
    isShowingFilters,
    filtersInitialized,
    appliedFilters,
    isLoading: isLoadingFilters,
    filters,
    filterValues,
    onSubmit: onSubmitFilters,
    onFilterToggle,
    onChange: onFiltersChange,
    onReset: onResetFilters,
    setFiltersInitialized,
  } = useFilters({
    filterFetcher: useCallback(
      () =>
        getRepairsFilters({
          isActive: selectedTab === 'O' ? true : false,
        }),
      [selectedTab]
    ),
  });

  const dataFetcher: GridDataFetcher<IRepair> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      if (!filtersInitialized && !isLoadingFilters && Object.values(appliedFilters).length === 0) {
        return {
          continueLoading: true,
        };
      }
      const statusFilter = appliedFilters.Statuses === undefined ? '' : appliedFilters?.Statuses[0];
      const status = statusReset === true ? currentTabStatus : statusFilter;
      try {
        const res = await getRepairs({
          sortDirection: sortDirection || 'desc',
          sortBy: sortColumn || 'whenCreated',
          perPage,
          page: page + 1,
          siteId: siteId ?? appliedFilters?.SiteId?.[0],
          status: currentTabStatus === OpenStatus ? status : currentTabStatus,
          whenCreatedStartDate: appliedFilters?.WhenCreated?.[0] ?? undefined,
          whenCreatedEndDate: appliedFilters?.WhenCreated?.[1] ?? undefined,
          agreementStatus: appliedFilters?.AgreementStatus?.[0] ?? undefined,
          invoiceStatus: appliedFilters?.InvoiceStatus?.[0] ?? undefined,
          progress: appliedFilters?.Progress?.[0] ?? undefined,
          visitStatus: appliedFilters?.VisitStatus?.[0] ?? undefined,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading One-time services, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appliedFilters]
  );

  const {
    rows,
    isLoading: isLoadingServices,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    setRows,
    refetch: refetchOTServices,
  } = useDataGrid<IRepair>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'ots-landing-grid',
      sortColumn: 'whenCreated',
      sortDirection: 'desc',
    },
    dataFetcher,
  });
  const columns = useMemo(
    () =>
      [
        {
          headerName: 'Date',
          field: 'whenCreated',
          flex: 1,
          disableColumnMenu: true,
          maxWidth: 100,
          renderCell: (params: GridRenderCellParams<IRepair>) => {
            return (
              <Link
                to={`/services/ots/${params?.row.repairId}?redirect=${
                  redirect ? redirect : '/services/ots'
                }`}
              >
                <Tooltip title="Edit OTS" placement="bottom">
                  <span>{formatDate(params?.row.whenCreated)}</span>
                </Tooltip>
              </Link>
            );
          },
        },
        {
          headerName: 'OTS Status',
          field: 'status',
          flex: 1,
          maxWidth: 120,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams<IRepair>) => {
            return (
              <Typography
                sx={
                  params?.row.status === 'Open'
                    ? { color: '#41D090', fontWeight: 'bold', fontSize: '14px' }
                    : { fontWeight: 'bold', fontSize: '14px' }
                }
              >
                {params?.row.status}
              </Typography>
            );
          },
        },
        !accountId && {
          headerName: 'Site',
          field: 'siteName',
          flex: 1.5,
          maxWidth: 250,
          renderCell: (params: GridRenderCellParams<IRepair>) => {
            const childComponent = (
              <Tooltip placement="bottom" title={`View customer's site`}>
                <span>{params?.row.siteName}</span>
              </Tooltip>
            );
            return v2Customers ? (
              <Link
                to={`/customers/${params?.row.accountId}?activeTab=sites&siteId=${params?.row.siteId}&redirect=/services/ots`}
              >
                {childComponent}
              </Link>
            ) : (
              <ExternalLink to={`${legacyUrl}/Office/Site/View/${params?.row.siteId}`}>
                {childComponent}
              </ExternalLink>
            );
          },
        },
        {
          headerName: 'Description',
          field: 'serviceType',
          flex: 1,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams<IRepair>) => {
            return (
              <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                {params?.row.serviceType}
              </Typography>
            );
          },
        },
        selectedTab === 'O' && {
          headerName: 'Progress',
          field: 'repairProgress',
          flex: 1,
          sortable: false,
          maxWidth: 220,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams<IRepair>) => {
            const row = params?.row;
            return (
              <Typography
                sx={
                  row?.status === 'Hold For Parts' || row?.status === 'Hold For Calls'
                    ? { color: theme.palette.error.main, fontWeight: 'bold', fontSize: '14px' }
                    : { fontWeight: 'bold', fontSize: '14px' }
                }
              >
                {row?.status === 'Hold For Parts' || row?.status === 'Hold For Calls'
                  ? row?.status
                  : row?.repairProgress}
              </Typography>
            );
          },
        },
        {
          headerName: 'Agreement Status',
          field: 'agreementStatus',
          sortable: false,
          flex: 1,
          maxWidth: 180,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams<IRepair>) => {
            const childComponent = (
              <Tooltip placement="bottom" title="View Agreement">
                <span>
                  {params?.row?.hasApprovalOverride
                    ? `Override ${
                        params?.row.approvalDate ? formatDate(params?.row.approvalDate) : ''
                      }`
                    : params?.row.agreementStatus}
                </span>
              </Tooltip>
            );
            return params?.row.repairAgreementLink ? (
              <ExternalLink to={`${params?.row.repairAgreementLink}`} target="_blank">
                {childComponent}
              </ExternalLink>
            ) : (
              <span>{params?.row.agreementStatus}</span>
            );
          },
        },
        {
          headerName: 'Expiration Date',
          field: 'expirationDate',
          flex: 1,
          disableColumnMenu: true,
          sortable: false,
          maxWidth: 160,
          renderCell: (params: GridRenderCellParams<IRepair>) => {
            return <span>{formatDate(params?.row.expirationDate)}</span>;
          },
        },
        selectedTab === 'O' && {
          headerName: 'Next Scheduled Visit',
          field: 'nextRepairVisit',
          flex: 1,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams<IRepair>) => {
            const { nextRepairVisit } = params.row;
            if (nextRepairVisit) {
              const { startTime, endTime, assignedTo, serviceDate } = nextRepairVisit;
              // format a date from this 2023-07-19T00:00:00+00:00 -> to this 2023/07/19 so we get the correct date
              const correctDate = serviceDate
                ? serviceDate?.split?.('T')?.[0]?.replaceAll('-', '/')
                : '';
              const serviceDateShort = correctDate
                ? `${format(new Date(correctDate), 'M')}/${format(new Date(correctDate), 'dd')}`
                : '';
              const formatStartTime = startTime ? format(new Date(startTime), 'h:mma') : '';
              const formatEndTime = endTime ? format(new Date(endTime), 'h:mma') : '';
              let timeWindow =
                !!formatStartTime && !!formatEndTime ? `${formatStartTime} - ${formatEndTime}` : '';
              if (startTime && !endTime) {
                timeWindow = formatStartTime;
              }
              let data = [serviceDateShort, timeWindow, assignedTo];
              return (
                <Tooltip
                  placement="bottom"
                  title={params?.row.nextRepairVisit && data.filter(Boolean).join(', ')}
                >
                  <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {params?.row.nextRepairVisit && data.filter(Boolean).join(', ')}
                  </Box>
                </Tooltip>
              );
            } else {
              return <></>;
            }
          },
        },
        {
          headerName: 'Invoice',
          field: 'invoiceCreated',
          sortable: false,
          disableColumnMenu: true,
          flex: 1,
          renderCell: (params: GridRenderCellParams<IRepair>) => {
            const row = params?.row;
            const invoiceDate = params?.row.invoiceCreated;

            return row.invoiceId ? (
              <Link
                to={`/billing/invoices/${row?.invoiceId}?redirect=${
                  redirect ? redirect : '/services/ots'
                }`}
              >
                <Tooltip placement="bottom" title="View Invoice">
                  <>
                    <span>{formatDate(invoiceDate)}</span>
                    {invoiceDate && <span> - {row?.invoicePosted ? 'Posted' : 'Pending'}</span>}
                  </>
                </Tooltip>
              </Link>
            ) : (
              <>
                <span>{formatDate(invoiceDate)}</span>
                {invoiceDate && <span> - {row?.invoicePosted ? 'Posted' : 'Pending'}</span>}
              </>
            );
          },
        },
        isEditable && {
          headerName: '',
          field: 'actions',
          sortable: false,
          disableColumnMenu: true,
          align: 'center',
          renderCell: (params: GridRenderCellParams<IRepair>) => {
            const renderSiteListItem = (
              <ListItem disablePadding>
                <ListItemButton>Edit Site</ListItemButton>
              </ListItem>
            );
            // Per Grids > Actions Column Pattern,
            // render as an action menu when multiple edit links,
            // and render as single button when only one action
            return !accountId ? (
              <TableActionsMenu labelContext="OTS" id={`action-menu-${params?.row?.repairVisitId}`}>
                <Link
                  to={`/services/ots/${params?.row.repairId}?redirect=${
                    redirect ? encodeURIComponent(redirect) : '/services/ots'
                  }`}
                >
                  <ListItem disablePadding>
                    <ListItemButton>Edit OTS</ListItemButton>
                  </ListItem>
                </Link>
                {v2Customers ? (
                  <>
                    <Link
                      to={`/customers/${params?.row?.accountId}?activeTab=sites&siteId=${params?.row?.siteId}&redirect=/services/ots`}
                    >
                      <ListItem disablePadding>
                        <ListItemButton>View Site</ListItemButton>
                      </ListItem>
                    </Link>
                    <Link
                      to={`/customers/${params?.row?.accountId}?activeTab=sites&siteId=${params?.row?.siteId}&isEdit=true&redirect=/services/ots}`}
                    >
                      {renderSiteListItem}
                    </Link>
                  </>
                ) : (
                  <ExternalLink to={`${legacyUrl}/Office/Account/View/${params?.row?.accountId}`}>
                    {renderSiteListItem}
                  </ExternalLink>
                )}
                {params?.row.repairAgreementLink && (
                  <ExternalLink to={params?.row.repairAgreementLink} target="_blank">
                    <ListItem disablePadding>
                      <ListItemButton>View Agreement</ListItemButton>
                    </ListItem>
                  </ExternalLink>
                )}
                {params?.row.invoiceId && (
                  <Link
                    to={`/billing/invoices/${params?.row.invoiceId}?redirect=${
                      redirect ? redirect : '/services/ots'
                    }`}
                  >
                    <ListItem disablePadding>
                      <ListItemButton>View Invoice</ListItemButton>
                    </ListItem>
                  </Link>
                )}
              </TableActionsMenu>
            ) : (
              <Box
                textAlign="right"
                display="flex"
                justifyContent="flex-end"
                className="print--none"
              >
                <Stack direction="row" gap={1}>
                  <IconButton
                    color="primary"
                    title="Edit OTS"
                    component={Link}
                    to={`/services/ots/${params?.row.repairId}?redirect=${
                      redirect ? redirect : '/services/ots'
                    }`}
                  >
                    <FontAwesomeIcon icon={faEdit} size="sm" />
                  </IconButton>
                </Stack>
              </Box>
            );
          },
        },
      ].filter(Boolean) as GridColDef[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, appliedFilters, accountId, isEditable]
  );

  useMemo(() => {
    switch (selectedTab) {
      case 'X':
        setCurrentTabStatus(CancelledStatus);
        break;
      case 'C':
        setCurrentTabStatus(ClosedStatus);
        break;
      case 'O':
      default:
        setCurrentTabStatus(OpenStatus);
        break;
    }
  }, [selectedTab]);

  return (
    <>
      <Card>
        <CardTitle
          title={title ? title : 'One-Time Services'}
          mobileWrap
          marginBottom={0}
          overrideMobileView={700}
          action={
            <>
              <Button
                onClick={onFilterToggle}
                startIcon={
                  <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
                }
                className={clsx('print--none')}
                color="secondary"
                size="small"
                disabled={isLoadingServices}
                fullWidth={isMobile}
                data-testid="filters-button"
              >
                Filters
              </Button>
              {!isMobile && (
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  aria-hidden="true"
                  className="grey"
                />
              )}
              <Button
                onClick={() => {
                  setOtsSchedulerModal(true);
                }}
                className={clsx('print--none')}
                color="secondary"
                size="small"
                disabled={isLoadingServices}
                startIcon={<FontAwesomeIcon icon={faEye} />}
                data-testid="scheduler-button"
              >
                Scheduler
              </Button>
              <Button
                onClick={() => {
                  if (onNavigation) {
                    onNavigation(
                      `/services/ots/new${redirect ? `?redirect=${redirect}` : ''}${
                        accountId ? `&accountId=${accountId}` : ''
                      }${siteId ? `&siteId=${siteId}` : ''}`
                    );
                  } else {
                    history.push(
                      `/services/ots/new${redirect ? `?redirect=${redirect}` : ''}${
                        accountId ? `&accountId=${accountId}` : ''
                      }${siteId ? `&siteId=${siteId}` : ''}`
                    );
                  }
                }}
                color="secondary"
                size="small"
                disabled={isLoadingServices}
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                sx={{ whiteSpace: 'nowrap' }}
                data-testid="add-ots-button"
              >
                Add One-Time Service
              </Button>
            </>
          }
          withExpand={isCollapsible}
          initialExpand={initialExpand}
          overrideExpand={isExpanded}
        >
          <CardFiltersLayout isOpen={isShowingFilters}>
            <FilterForm
              filters={filters}
              values={filterValues}
              filterLayouts={filtersLayout}
              defaultLayout={{ xs: 12 }}
              onSubmit={values => {
                setStatusReset(false);
                onPageChange(0);
                onSubmitFilters(values);
              }}
              onChange={onFiltersChange}
              isSubmitting={isLoadingServices}
              onReset={() => {
                setStatusReset(true);
                onResetFilters({
                  Statuses: [currentTabStatus],
                });
                onSubmitFilters({ statuses: [currentTabStatus] });
              }}
            />
          </CardFiltersLayout>
          {selectedTab && (
            <Box mt={isCollapsible ? -1.5 : undefined}>
              <Tabs
                id="one-time-service-tabs"
                selectedTab={selectedTab}
                setSelectedTab={async val => {
                  onPageChange(0);
                  onResetFilters({});
                  setRows([]);
                  setFiltersInitialized(false);
                  setSelectedTab(val);
                }}
                tabs={
                  [
                    {
                      key: 'O',
                      title: 'Current',
                      disabled: isLoadingServices,
                    },
                    {
                      key: 'C',
                      title: 'Completed',
                      disabled: isLoadingServices,
                    },
                    {
                      key: 'X',
                      title: 'Cancelled',
                      disabled: isLoadingServices,
                    },
                  ].filter(Boolean) as ITab[]
                }
              />
            </Box>
          )}

          <Box mt={1}>
            <ServerSideDataGrid
              autoHeight
              getRowId={(row: IRepair) => row.repairId!}
              rows={rows}
              columns={columns}
              loading={isLoadingServices}
              rowCount={recordCount}
              page={page}
              pageSize={perPage}
              sortModel={sortModel}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onSortModelChange={onSortModelChange}
              hideFooter={rows.length === 0}
              hasMobileLayout
              mobileProps={{
                mobileCustomDefaultAccessor: (row: IRepair) => {
                  return hyphenSeparateTwoInputs(
                    (row?.whenCreated && formatDate(row?.whenCreated)) ?? '',
                    row?.siteName ?? ''
                  );
                },
                truncateAccordionLabel: true,
                showHandleActions: true,
              }}
            />
          </Box>
          <OTSSchedulerModal
            open={isOtsSchedulerModalOpen}
            onClose={(shouldReload?: boolean) => {
              if (shouldReload) {
                refetchOTServices();
              }
              setOtsSchedulerModal(false);
            }}
            allowCreate={false}
          />
        </CardTitle>
      </Card>
    </>
  );
};
