import {
  SelectAsync,
  TextField,
  MultipleEmailInput,
  Wysiwyg,
  Card,
  ModalSaveSection as FormSaveSection,
  Loader,
  ConfirmPrompt,
  CardTitle,
} from '../../../components';
import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, FormControlLabel, Checkbox, Box, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { defaultUnsavedChangesMessage } from '../../../constants';
import {
  getEmailTemplateEventTypes,
  createEmailTemplate,
  updateEmailTemplate,
  getEmailTemplate,
  getEmailTemplateCampaigns,
} from '../../../fetch';
import { IDropdownResponse, IEmailTemplateDetail } from '../../../models';
import { UserContext } from '../../../context';
import { deepEqual } from 'fast-equals';
import { EmailTemplateSubstitutions } from './email-template-subsitutions';
import { FollowUpNotesCard } from './follow-up-notes';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { EMAIL_EVENTS } from '../../../constants/email-templates';

const FORM_VALIDATION = Yup.object().shape({
  emailEvent: Yup.string().required('Required'),
  campaignId: Yup.mixed().when('emailEvent', {
    is: (val: string) => val === 'S',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  templateName: Yup.string().required('Required').max(30, 'Max 30 characters'),
  sortOrder: Yup.number().nullable(),
  isSuppressed: Yup.boolean(),
  emailBccs: Yup.array(Yup.string().nullable()).nullable(),
  emailSubject: Yup.string().required('Required'),
  emailBody: Yup.string().required('Required'),
});

const CAMPAIGN_BANNER_GENERIC_DEFAULT = '84e0bc7c-29b2-45e4-8db5-7a27c1b46007';

export const EmailTemplatesDetailsPage = () => {
  const { user } = useContext(UserContext);
  const { emailTemplateId }: { emailTemplateId: string } = useParams();
  const isNewEmailTemplate = emailTemplateId === 'new';
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isAllExpanded, setIsAllExpanded] = useState<boolean | undefined>(true);

  const { isLoading, data: currentEmailTemplate } = useQuery<IEmailTemplateDetail, Error>(
    ['getEmailTemplate', emailTemplateId],
    () => getEmailTemplate(emailTemplateId!),
    {
      enabled: !!emailTemplateId && emailTemplateId !== 'new',
    }
  );
  return (
    <>
      <Box
        mb={1}
        mt={-2}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        className="print--none"
      >
        <Button
          variant="text"
          onClick={() => setIsAllExpanded(!isAllExpanded ? true : false)}
          startIcon={isAllExpanded ? <ExpandLess /> : <ExpandMore />}
        >
          {isAllExpanded ? 'Collapse All' : 'Expand All'}
        </Button>
      </Box>
      {(isLoading || isUpdating) && <Loader position="centered" type="overlay" />}
      <Formik
        enableReinitialize={true}
        initialValues={{
          emailEvent: currentEmailTemplate?.emailTemplateEvent ?? 'S',
          templateName: currentEmailTemplate?.emailTemplateName ?? '',
          sortOrder:
            currentEmailTemplate?.sortOrder && currentEmailTemplate?.sortOrder !== null
              ? `${currentEmailTemplate?.sortOrder!}`
              : '',
          isSuppressed: currentEmailTemplate?.isSuppressed ?? false,
          emailBccs: currentEmailTemplate?.emailBccs ?? [],
          emailSubject: currentEmailTemplate?.subject ?? '',
          emailBody: currentEmailTemplate?.emailTemplateBody ?? '',
          campaignId: currentEmailTemplate?.campaignId ?? CAMPAIGN_BANNER_GENERIC_DEFAULT,
          emailCampaignId: currentEmailTemplate?.emailCampaignId ?? '',
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={async values => {
          try {
            setIsUpdating(true);
            if (isNewEmailTemplate) {
              await createEmailTemplate({
                ...values,
                sortOrder: values.sortOrder || 0,
                officeId: user?.officeId!,
              });
              enqueueSnackbar('Email Template was created successfully!', {
                variant: 'success',
              });
            } else {
              await updateEmailTemplate(currentEmailTemplate?.emailTemplateId!, {
                ...values,
                sortOrder: values.sortOrder || 0,
                officeId: user?.officeId!,
              });
              enqueueSnackbar('Email Template updated!', {
                variant: 'success',
              });
            }
            history.replace('/setup/email-templates');
          } catch (err: any) {
            enqueueSnackbar(
              err?.Detail ||
                `Error ${
                  isNewEmailTemplate ? 'creating' : 'editing'
                } email template, please try again.`,
              { variant: 'error' }
            );
          } finally {
            setIsUpdating(false);
          }
        }}
      >
        {({
          isValid,
          dirty,
          initialValues,
          values,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <>
              <ConfirmPrompt
                when={!deepEqual(initialValues, values) && !isSubmitting && !isLoading}
                message={defaultUnsavedChangesMessage}
              />
              <Form onSubmit={handleSubmit}>
                <Card>
                  <CardTitle
                    title="Email Template Details"
                    withExpand
                    overrideExpand={isAllExpanded}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <SelectAsync
                          name="emailEvent"
                          label="Email Event"
                          value={values.emailEvent}
                          required
                          apiRequest={() => getEmailTemplateEventTypes()}
                          transformResponse={(response: IDropdownResponse[]) => {
                            if (isNewEmailTemplate) {
                              const emailTypesToExclude: string[] = [
                                EMAIL_EVENTS.PaymentReceipt,
                                EMAIL_EVENTS.PaymentDeclined,
                              ];

                              const filteredEmailTypes = response
                                .filter(item => !emailTypesToExclude.includes(item.value))
                                .map(record => ({
                                  label: record.description,
                                  value: record.value,
                                }));

                              return filteredEmailTypes;
                              
                            } else {
                              return response.map(record => ({
                                label: record.description,
                                value: record.value,
                              }));
                            }
                          }}
                          onChange={e => {
                            setFieldValue('emailEvent', e.target.value);
                          }}
                          error={touched?.emailEvent && !!errors?.emailEvent}
                          helperText={touched?.emailEvent && errors?.emailEvent}
                        />
                      </Grid>
                      {values.emailEvent === 'S' && (
                        <Grid item xs={12} md={6}>
                          <SelectAsync
                            name="campaignId"
                            label="Campaign Banner"
                            value={values.campaignId}
                            required
                            apiRequest={() => getEmailTemplateCampaigns()}
                            transformResponse={(response: IDropdownResponse[]) => {
                              return response.map(record => ({
                                label: record.description,
                                value: record.value,
                              }));
                            }}
                            error={touched?.campaignId && !!errors?.campaignId}
                            helperText={touched?.campaignId && errors?.campaignId}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Template Name"
                          name="templateName"
                          required
                          value={values.templateName}
                          error={touched?.templateName && !!errors?.templateName}
                          helperText={touched?.templateName && errors?.templateName}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          label="Sort Order"
                          name="sortOrder"
                          type="number"
                          value={values.sortOrder}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Do Not Send"
                          name="isSuppressed"
                          checked={values.isSuppressed}
                          onChange={(_, checked) => {
                            setFieldValue('isSuppressed', checked);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MultipleEmailInput
                          fullWidth
                          size="small"
                          placeholder="test@example.com"
                          label="BCC"
                          value={values.emailBccs}
                          onChange={(_e, newEmailsValue) => {
                            setFieldValue('emailBccs', newEmailsValue);
                          }}
                          helperText="Use BCC to send a copy of the email to people within your company"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Subject"
                          name="emailSubject"
                          required
                          error={touched?.emailSubject && !!errors?.emailSubject}
                          helperText={touched?.emailSubject && errors?.emailSubject}
                        />
                      </Grid>
                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} md={8}>
                          <Wysiwyg
                            label="Body"
                            required
                            value={values.emailBody}
                            onChange={val => {
                              setFieldValue('emailBody', val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <EmailTemplateSubstitutions selectedEmailEvent={values.emailEvent} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent={'space-between'}>
                      <FormSaveSection
                        handleCancel={() => {
                          history.push('/setup/email-templates');
                        }}
                        isSaveDisabled={!isValid}
                        showBack={!dirty}
                      />
                    </Grid>
                  </CardTitle>
                </Card>
              </Form>
              {!isNewEmailTemplate && (
                <FollowUpNotesCard
                  emailTemplateId={emailTemplateId}
                  overrideExpand={isAllExpanded}
                />
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};
