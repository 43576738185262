import { FC, useContext } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// Components
import { Modal, Loader, TextField, ModalSaveSection, Checkbox } from '../../../components';
import {
  Box,
  Fade,
  Typography,
  Grid,
  Divider,
  FormControlLabel,
} from '@mui/material';

// fetch
import { updateBillingGroup, createBillingGroup } from '../../../fetch';
import { IBillingGroup } from '../../../models';

interface IAddEditBillingGroupModal {
  open: boolean;
  onClose: () => void;
  currentBillingGroup?: IBillingGroup | null;
  fetchBillingGroups: () => void;
}

const Schema = Yup.object().shape({
  billingGroupId: Yup.string(),
  description: Yup.string().required('Required'),
  billAsInvoice: Yup.bool(),
});

export const AddEditBillingGroupModal: FC<IAddEditBillingGroupModal> = ({
  open,
  onClose,
  currentBillingGroup,
  fetchBillingGroups,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          billingGroupId: currentBillingGroup?.billingGroupId ?? '',
          officeId: user?.officeId ?? '',
          description: currentBillingGroup?.description ?? '',
          billAsInvoice: currentBillingGroup?.billAsInvoice ?? false,
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data = {
            ...values,
          };
          try {
            currentBillingGroup
              ? await updateBillingGroup(data)
              : await createBillingGroup(data);
            enqueueSnackbar(
              currentBillingGroup
                ? 'Successfully updated billing group!'
                : 'Successfully created billing group!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchBillingGroups();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving billing group, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({ resetForm, isSubmitting, handleSubmit, dirty, isValid, values, setFieldValue }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
              >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box marginBottom="2rem">
                    <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                      {currentBillingGroup ? 'Edit Billing Group' : 'Add Billing Group'}
                    </Typography>
                    <Divider />
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="description" label="Description" required inputProps={{ maxLength: 50 }} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                      <FormControlLabel 
                        control={<Checkbox name="billAsInvoice" checked={values.billAsInvoice}/>}
                        label="Bill As Invoice" 
                        onChange={(_e, checked) => setFieldValue('billAsInvoice', checked)}
                      />
                      </Grid>
                    </Grid>
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                      }}
                      isSaveDisabled={!(dirty) || isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
