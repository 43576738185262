import { useHistory } from 'react-router-dom';
import { Link, Loader } from '../../components';
import { voidTransaction, deleteInvoice, postRecallInvoice } from '../../fetch';
import { Box, Button } from '@mui/material';
import { scrollToTop } from '../../helpers';
import { FC, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useConfirm } from '../../hooks';
import { ITransactionDetail } from '../../models';

interface ITransactionPageDetailsButtonsProps {
  transaction?: ITransactionDetail;
  redirect: string | null;
}

export const TransactionPageDetailsButtons: FC<ITransactionPageDetailsButtonsProps> = ({
  transaction,
  redirect,
}) => {
  const [isVoidingTransaction, setIsVoidingTransaction] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRecallingMPS, setRecallingMPS] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const confirm = useConfirm();

  const handleDelete = async () => {
    const confirmed = await confirm('Are you sure you want to delete this transaction?');
    if (!confirmed) {
      return;
    }
    setIsDeleting(true);
    try {
      await deleteInvoice(transaction?.transactionId!);
      history.push(redirect ?? `/customers/${transaction?.accountId}`);
      enqueueSnackbar(`Transaction was successfully deleted`, {
        variant: 'success',
      });
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error deleting transaction, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const recallMPSTransaction = async () => {
    const result = await confirm('Are you sure you want to recall this transaction?');
    if (result) {
      setRecallingMPS(true);
      try {
        await postRecallInvoice(transaction?.transactionId!);
        enqueueSnackbar(`Transaction recalled!`, {
          variant: 'success',
        });
        scrollToTop();
        history.push(redirect ?? `/customers/${transaction?.accountId}?activeTab=transactions`);
      } catch (err: any) {
        enqueueSnackbar(err?.Detail || `Error recalling transaction, please try again.`, {
          variant: 'error',
        });
      } finally {
        setRecallingMPS(false);
      }
    }
  };
  return (
    <>
      {(isVoidingTransaction || isDeleting || isRecallingMPS) && (
        <Loader type="overlay" position="centered" />
      )}
      <Box display="flex" gap={1}>
        <Box flex={1} display="flex" gap={1}>
          <Link
            to={redirect ?? `/customers/${transaction?.accountId}?activeTab=transactions`}
            type="black"
          >
            <Button color="inherit">Cancel</Button>
          </Link>
          {/* Show recall only for Monthly Pool Service */}
          {transaction?.tranCode === 'MPS' && (
            <Button
              color="secondary"
              type="button"
              disabled={isRecallingMPS}
              onClick={recallMPSTransaction}
            >
              Recall
            </Button>
          )}
        </Box>
        {(transaction?.isVoidable || transaction?.canDelete) && (
          <Box display="flex" gap={1}>
            {transaction?.canDelete && (
              <Button
                color="error"
                type="button"
                title="Delete transaction"
                onClick={handleDelete}
                disabled={isDeleting}
              >
                Delete
              </Button>
            )}
            {transaction?.isVoidable && (
              <Button
                color="secondary"
                type="button"
                disabled={isVoidingTransaction}
                onClick={async () => {
                  const result = await confirm(`Are you sure you want to void this transaction?`);

                  if (result) {
                    try {
                      setIsVoidingTransaction(true);
                      await voidTransaction(transaction?.transactionId!);

                      enqueueSnackbar('Void success!', {
                        variant: 'success',
                      });
                      history.push(redirect ?? '/payments');
                    } catch (e: any) {
                      enqueueSnackbar(
                        e?.Detail ?? `Error on voiding transaction, please try again`,
                        {
                          variant: 'error',
                        }
                      );
                    } finally {
                      setIsVoidingTransaction(false);
                    }
                  }
                }}
              >
                Void
              </Button>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};
