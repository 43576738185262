import { FC } from 'react';
import clsx from 'clsx';
import { Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { SaveButton } from '../../button';

interface SaveAndCancelStripProps {
  allowSave: boolean;
  allowCancel: boolean;
  onSave: () => unknown;
  onCancel: () => unknown;
  changeCount: number;
}

export const SaveAndCancelStrip: FC<SaveAndCancelStripProps> = ({
  allowCancel = true,
  allowSave = true,
  onSave,
  onCancel,
  changeCount,
}) => {
  const classes = useStyles();
  let saveLabel = 'Save';

  if (changeCount) {
    if (changeCount === 1) {
      saveLabel = 'Save (1 CHANGE)';
    } else {
      saveLabel = `Save (${changeCount} CHANGES)`;
    }
  }
  return (
    <div className={clsx(classes.saveAndCancelStripContainer, 'print--none')}>
      <Button color="inherit" onClick={onCancel} disabled={!allowCancel}>
        Cancel
      </Button>
      <SaveButton handleSave={onSave} disabled={!allowSave} text={saveLabel} />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  saveAndCancelStripContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    padding: 10,
    backgroundColor: theme.palette.grey[500],
    position: 'sticky',
    bottom: 0,
    zIndex: 10,
    '@media print': {
      display: 'none',
    },
  },
}));
